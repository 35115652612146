import React from "react";
import { Dialog, DialogContent } from "@mui/material";

interface ImagePopupProps {
  imageSrc: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImagePopup: React.FC<ImagePopupProps> = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80%", 
          height: "60%", 
          aspectRatio: "1 / 1",
        }}
      >
        <img
          src={props.imageSrc}
          alt="image"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            margin: "auto",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImagePopup;
