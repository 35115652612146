import React, { useState } from "react";
import "./CustomButton.scss";
import { BeatLoader } from "react-spinners/";
import { primaryColor } from "../../Shared/globalVar";

interface CustomButtonProps {
  text: String;
  isSecondary?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

const CustomButton: React.FunctionComponent<CustomButtonProps> = (props) => {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => {
    if (props.isSecondary === true) {
      setHovered(!hovered);
    }else{
      setHovered(false);

    }
  };
  return (
    <div
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={props.onClick && props.isLoading !== true ? props.onClick : (() => null)}
      className={
        "customButton " +
        (props.isSecondary === true && !hovered ? "customButtonSecondary" : "")
      }
    >
     {props.isLoading === true ? <BeatLoader color={ props.isSecondary === true ? primaryColor : 'white' } size={14} /> :  <p className="customButtonText">{props.text}</p>}
    </div>
  );
};

export default CustomButton;
