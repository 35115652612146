import axios from '../Api/axios';
import { IAuth } from '../Models/IAuth';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth() as any;

    const refresh = async () => {
        console.log('in refreshhh')
        const response = await axios.get('/owneruser/refresh', {
            withCredentials: true
        });
        setAuth((prev: IAuth ) => {
            console.log(JSON.stringify(prev));
            console.log(response.data.accessToken);
            return { ...prev, 
                permissions: response.data.permissions,
                token: response.data.accessToken }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
