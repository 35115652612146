import React, { useState } from "react";

import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Autocomplete, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface FormInputTextProps {
  name: string;
  control: any;
  label: any;
  value?: any;
  rules: any;
  type?: string;
  inputType?: string;
  disabled?: boolean;
  options?: any;
  onChange?: any;
  style?: any;
  multiline?: any;
  minRows?: any;

}

const FormInputText: React.FunctionComponent<FormInputTextProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // const validate = (value: string) => {
  //   const matches = value.match(
  //     /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
  //   );
  //   return matches!.length > 0 || "Not a Number";
  // };

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.value}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) =>
        props.inputType === "autocomplete" ? (
          <Autocomplete
            disablePortal
            options={props.options}
            onChange={(e, data) => {onChange(data);  props.onChange && props.onChange(data)}}
            value={value}
            renderInput={(params) => <TextField {...params} label={
              props.label[0].toUpperCase() + props.label.substr(1).toLowerCase()
            } />}
          />
        ) : (
          <TextField
            disabled={props.disabled ?? false}
            color="primary"
            type={props.type && showPassword === false ? props.type : "text"}
            helperText={error ? error.message : null}
            size="small"
            error={!!error}
            style={props.style}
            multiline={props.multiline}
            minRows={props.minRows}
            onChange={onChange}
            value={value}
            fullWidth
            label={
              props.label[0].toUpperCase() + props.label.substr(1).toLowerCase()
            }
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: props.type === "password" && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )
      }
    />
  );
};

export default FormInputText;
