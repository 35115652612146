import SnackbarError from "./SnackbarError";

interface SnackbarErrorClientProps {
    client: any,

}

const SnackbarErrorClient: React.FunctionComponent<SnackbarErrorClientProps> = (props) => {
  return (
    <SnackbarError
    message={props.client?.error}
    onClose={() => props.client?.clearError()}
  />
  );
};

export default SnackbarErrorClient;
