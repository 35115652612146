import React, { useEffect, useState } from "react";
import "./SideBar.scss";
import { FiHome } from "react-icons/fi";
import { BiCamera, BiCameraHome, BiCategoryAlt, BiHome, BiTag, BiUser } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { BiCartAlt, BiCloset } from "react-icons/bi";
import { MdReviews } from "react-icons/md";
import { LuShirt } from "react-icons/lu";
import { BiStore, BiSolidCity, BiSolidDiscount, BiShoppingBag, BiMoney, BiStar, BiSolidMegaphone} from "react-icons/bi";
import { MdOutlineDiscount} from "react-icons/md";
import logo from "../../Assets/Images/Logos/flagak_logo_transparent.png";
import { Link } from "react-router-dom";

interface SideBarProps {}

const SideBar: React.FunctionComponent<SideBarProps> = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  let dashboardItems = [
    // {
    //   name: "Dashboard",
    //   icon: FiHome,
    // },
    {
      name: "Products",
      icon: LuShirt,
      goTo: "/products",
    },
    {
      name: "Vendors",
      icon: BiStore,
      goTo: "/vendors",
    },
    {
      name: "Categories",
      icon: BiCategoryAlt,
      goTo: "/categories",
    },
    {
      name: "Provinces",
      icon: BiSolidCity,
      goTo: "/provinces",
    },
    {
      name: "Orders",
      icon: BiCartAlt,
      goTo: "/orders",
    },
    {
      name: "Inventory",
      icon: BiShoppingBag,
      goTo: "/inventory",
    },
    {
      name: "Reviews",
      icon: MdReviews,
      goTo: "/reviews",
    },
    // {
    //   name: "Flagak Discounts",
    //   icon: MdOutlineDiscount,
    //   goTo: "/discounts",
    // },
    {
      name: "Promocodes",
      icon: BiSolidDiscount,
      goTo: "/promocodes",
    },
    {
      name: "Order Transactions",
      icon: BiMoney,
      goTo: "/ordertransactions",
    },
    {
      name: "Customers",
      icon: BiUser,
      goTo: "/customers",
    },
    {
      name: "Financial Transactions",
      icon: BiMoney,
      goTo: "/financialtransactions",
    },
    {
      name: "Ad Sections",
      icon: BiSolidMegaphone,
      goTo: "/adsections",
    },
    {
      name: "Home Sections",
      icon: BiHome,
      goTo: "/homesections",
    },
    {
      name: "Tourist Sections",
      icon: BiCamera,
      goTo: "/touristsections",
    },

  ];

  useEffect(() => {
    console.log("hooooo");
    for (let i = 0; i < dashboardItems.length; i++) {
      if (window.location.href.indexOf(dashboardItems[i].goTo) > -1) {
        setSelectedItem(i);
        console.log("hoooo2222o");

        return;
      }
    }
  }, []);

  return (
    <div className="sideBar">
      <img height={30} src={logo} alt="logo" />
      <div className="sideBarItems">
        {dashboardItems.map((object, i) => (
          <Link
            onClick={() => {
              console.log("selected");
              setSelectedItem(i);
            }}
            to={object.goTo ?? ""}
            key={i}
            className={
              "sideBarItem " + (selectedItem === i ? "selectedSideBarItem" : "")
            }
          >
            <object.icon size={16} className="sideBarItemIcon" />
            <p>{object.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SideBar;
