import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material";
import SideBar from "../Components/SideBar/SideBar";
import Header from "../Components/Header/Header";
import ProductList from "./ProductList/ProductList";
import RequireAuth from "../Components/RequireAuth";
import CategoryList from "./CategoryList/CategoryList";
import VendorList from "./VendorList/VendorList";
import ProvincesList from "./ProvincesList/ProvincesList";
import PromocodeList from "./PromocodeList/PromocodeList";
import OrderList from "./OrderList/OrderList";
import OrderTransactionList from "./OrderTransactionList/OrderTransactionList";
import CustomerList from "./CustomerList/CustomerList";
import FinancialTransactionList from "./FinancialTransactionList/FinancialTransactionList";
import HomeSectionList from "./HomeSectionList/HomeSectionList";
import TouristSectionList from "./TouristSectionList/TouristSectionList";
import AdSectionList from "./AdSectionList/AdSectionList";
import ReviewList from "./ReviewList/ReviewList";

function Layout() {
  return (
    <div className="app">
      <SideBar />
      <div className="appBody">
        <Header />
        <Routes>
          {/* <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/products" element={<ProductList />} />
          </Route> */}
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/products" element={<ProductList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/categories" element={<CategoryList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/vendors" element={<VendorList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/provinces" element={<ProvincesList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/promocodes" element={<PromocodeList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/customers" element={<CustomerList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/" element={<ProductList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/orders" element={<OrderList canChangeStatus={false}/>} />
          </Route>
          
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/inventory" element={<OrderList canChangeStatus />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/ordertransactions" element={<OrderTransactionList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/financialtransactions" element={<FinancialTransactionList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/reviews" element={<ReviewList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/adsections" element={<AdSectionList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/homesections" element={<HomeSectionList />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["superAdmin"]} />}>
            <Route path="/touristsections" element={<TouristSectionList />} />
          </Route>
          
        </Routes>
        {/* <Login /> */}
      </div>
    </div>
  );
}

export default Layout;
