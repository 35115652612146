import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaExternalLinkAlt, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TableList from "../../Components/Table/Table";

import { Button, Menu, MenuItem, Select, Snackbar } from "@mui/material";
import OrderList from "../OrderList/OrderList";
import SearchBarList from "../../Components/SearchBarList/SearchBarList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import formatDate from "../../Utils/formatDate";
import { pageSize } from "../../Shared/globalVar";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { VendorTransactionFilterEnum } from "../../Data/Enums";

interface FinancialTransactionListProps {}

const FinancialTransactionList: React.FunctionComponent<
  FinancialTransactionListProps
> = (props: FinancialTransactionListProps) => {
  const [vendorTransactions, setVendorTransactions] = useState([]);
  const getVendorTransactionsClient = useHttpClient();
  const updatePaymentStatusClient = useHttpClient();
  const searchBarClient = useHttpClient();
  const [searchBarResults, setSearchBarResults] = useState([]);
  const [displaySearchBarResults, setDisplaySearchBarResults] = useState(false);
  const dataGridApiRef = useGridApiRef();
  const [count, setCount] = useState(0);
  const [ displayVendorTransactionsByOrder, setDisplayVendorTransactionsByOrder] = useState(false);
  const [vendorTransactionIdOrdersFilter, setVendorTransactionIdOrdersFilter] = useState(undefined);

  const [status, setStatus] = useState(0);

  let vendorTransactionStatuses = [
    {
      name: "All",
      vendorTransactions: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: VendorTransactionFilterEnum.Paid,
      // name: "Paid",
      vendorTransactions: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: VendorTransactionFilterEnum.NotPaid,
      // name: "Not Paid",
      vendorTransactions: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: VendorTransactionFilterEnum.Returned,
      // name: "Returned",
      vendorTransactions: [],
      needsLoading: true,
      count: 0,
    }
  ];

  useEffect(() => {
    getVendorTransactions(true, false);
  }, [status]);

  const columns: GridColDef[] = [
    { field: "vendorTransactionId", headerName: "Transaction ID", width: 240,
      editable: true},
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 120,
      editable: true,
    },
    // {
    //   field: "topLevelCategoryName",
    //   headerName: "Category",
    //   width: 110,
    //   editable: true,
    // },
    {
      field: "orders",
      headerName: "Order Items",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setVendorTransactionIdOrdersFilter(params.row.id);
            setDisplayVendorTransactionsByOrder(true);
          }}
        />
      ),
    },
    // {
    //   field: "statusHistory",
    //   headerName: "Status History",
    //   width: 250,
    //   editable: false,
    // },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 240,
      editable: true,
    },
    // {
    //   field: "orderItemId",
    //   headerName: "Order Item ID",
    //   width: 240,
    //   editable: true,
    // },
    {
      field: "totalAmount",
      headerName: "Final Amount",
      width: 100,
      editable: false,
    },
    {
      field: "subtotalAmount",
      headerName: "Subtotal Amount",
      width: 120,
      editable: false,
    },
    {
      field: "deliveryFees",
      headerName: "Delivery Fees",
      width: 100,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Order Amount",
      width: 100,
      editable: false,
    },
    {
      field: "totalCommission",
      headerName: "Total Commission",
      width: 120,
      editable: false,
    },
    {
      field: "totalDiscount",
      headerName: "Total Discount",
      width: 100,
      editable: false,
    },
    {
      field: "totalDiscountOnFlagak",
      headerName: "Discount on Flagak",
      width: 140,
      editable: false,
    },
    {
      field: "totalDiscountOnVendor",
      headerName: "Discount on Vendor",
      width: 140,
      editable: false,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 60,
      editable: false,
    },
    {
      field: "isCanceled",
      headerName: "Is Cancelled",
      width: 100,
      editable: false,
    },
    {
      field: "isReturn",
      headerName: "Is Returned",
      width: 90,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 170,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: "isPaid",
      headerName: "Payment Status",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.row.isPaid}
          onChange={async (e) => {
            console.log(
              `Updating payment status. New status: ${e.target.value}`
            );
            await updatePaymentStatus(
              params.row.vendorTransactionId,
              e.target.value
            );
            await getVendorTransactions(true, true);
          }}
          style={{
            backgroundColor:
              params.row.isPaid === true
                ? "rgba(144, 238, 144, 0.5)"
                : params.row.isPaid === false
                ? "rgba(255, 182, 193, 0.5)"
                : "",
          }}
        >
          <MenuItem value="true">Paid</MenuItem>
          <MenuItem value="false">Not Paid</MenuItem>
        </Select>
      ),
    },
  ];

  const updatePaymentStatus = async (
    transactionId: string,
    paymentStatus: string
  ) => {
    try {
      const response = await updatePaymentStatusClient.sendRequest({
        url: `/owner/vendortransaction/paymentstatus`,
        method: "post",
        body: {
          transactionId,
          paymentStatus,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
        },
      });

      console.log(`updating payment status`);
      console.log(response);

      if (response) {
        // remove last 10 orders to show admin status of updated order
        setVendorTransactions(vendorTransactions.splice(-pageSize));
        
      }

      await getVendorTransactions(true);
    } catch (e) {
      console.log(updatePaymentStatusClient.error);
    }
  };

  const getVendorTransactionById = async (vendorTransactionId: string) => {
    try {
      const response = await searchBarClient.sendRequest({
        url: `/owner/vendortransaction/getbyid?vendorTransactionId=${vendorTransactionId}`,
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
      if (response) {
        console.log("fetching order by ID");
        console.log(response);
        setSearchBarResults(response);
        console.log(searchBarResults);
      }
    } catch (e) {
      console.log(e);
      console.log(searchBarClient.error);
    }
  };

  const getVendorTransactions = async (
    getCount: boolean,
    adminStatusUpdated?: boolean
  ) => {
    try {
      const currentPage =
        dataGridApiRef.current.state.pagination.paginationModel.page;

      const skipAmount = adminStatusUpdated ? currentPage * 10 : vendorTransactions.length;

      const response = await getVendorTransactionsClient.sendRequest({
        url: `/owner/vendortransaction?skip=${skipAmount}&descending=true`,
        method: "post",
        body: {
          getCount: getCount,
          status: status === 0 ? null : vendorTransactionStatuses[status].name
        },
        headers: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      });

      console.log(response);
      if (response && response.data) {
      

      let allVendorTransactions;

      // ensure that current page is re-fectched if admin status of a row is updated
      if (adminStatusUpdated) {
        allVendorTransactions = [...(response.data as [])];
      } else {
        allVendorTransactions = [...vendorTransactions, ...(response.data as [])];
      }
  

      setVendorTransactions(allVendorTransactions);

        if (getCount) {
          setCount(response.count);
          console.log("Response count: ", response.count);
        }
      }
    } catch (e) {
      console.log(e);
      console.log(getVendorTransactionsClient.error);
    }
  };

  return displaySearchBarResults ? (
    // show search bar results

    <SearchBarList
      toggleSearchBarList={() =>
        setDisplaySearchBarResults(!displaySearchBarResults)
      }
      columns={columns}
      rows={searchBarResults}
      isLoading={searchBarClient.isLoading}
    />
  ) : displayVendorTransactionsByOrder ? (
    // show the orders page filtered by vendortransaction
    
    <OrderList
      canChangeStatus={false}
      vendorTransactionId={vendorTransactionIdOrdersFilter}
      returnToOriginalComponent={() =>
        setDisplayVendorTransactionsByOrder(false)
      }
    />
  ) : (
    // otherwise, show the main VendorTransactions page

    <div className="page">
      <SnackbarErrorClient client={getVendorTransactionsClient} />

      <div className="pageUpper">
        <p className="pageTitle">Financial Transactions</p>
      </div>

      <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
            {vendorTransactionStatuses.map((object, i) => (
              <CustomButton
                key={i}
                text={object.name}
                onClick={() => {
                  setCount(0);
                  setVendorTransactions([]);
                  setStatus(i);
                  dataGridApiRef.current.setPage(0);
                }}
                isSecondary={status === i ? false : true}
              />
            ))}
          </div>
      
        <SearchBar
          fetchDataById={getVendorTransactionById}
          toggleSearchBarList={() => setDisplaySearchBarResults(!displaySearchBarResults)}
        />

        <TableList
          rows={vendorTransactions}
          isLoading={getVendorTransactionsClient.isLoading}
          getItems={(p) => getVendorTransactions(false)}
          columns={columns}
          dataGridApiRef={dataGridApiRef}
          rowsCount={count}
          showRowNumbers
          rowNumbersDescending
        />
    </div>
  )
};

export default FinancialTransactionList;
