import React, { useState } from "react";
import "./Login.scss";
import { useForm } from "react-hook-form";

import logo from "../../../Assets/Images/Logos/flagakLogo.svg";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import FormInputText from "../../../Components/FormInputText/FormInputText";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import axios from "../../../Api/axios";
import { Alert } from "@mui/material";
import useAuth from "../../../Hooks/useAuth";
import { Navigate, Route } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useHttpClient } from "../../../Hooks/HttpHook";

const LOGIN_URL = "/owneruser/logIn";

interface LoginProps {}

const Login: React.FunctionComponent<LoginProps> = () => {
  type FormInputs = {
    username: string;
    password: string;
  };

  const { setAuth }: any = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from.pathName || "/categories";

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    console.log(data);

    clearError();

    try {
      // setError("");
      let username = data.username;
      let password = data.password;
      const responseData = await sendRequest({
        url: LOGIN_URL,
        method: "post",
        body: {
          username,
          password,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
        isPrivate: false,
        withCredentials: true

      });

      const token = responseData?.token;
      const permissions = responseData?.permissions;
      const userType = responseData?.ownerType;
      console.log(responseData);

      setAuth({ username, userType, permissions, token });
      navigate(from, { replace: true });
    } catch (err: any) {}
  };
  return (
    <div className="loginPage">
      <img height={80} alt="flagakLogo" src={logo} />
      <div className="loginSquare">
        <p className="pageTitle">Login</p>

        <form onSubmit={handleSubmit(onSubmit)} className="loginForm">
          <FormInputText
            rules={{
              required: true,
              pattern: {
                value: /^(.+@.+|\d{10})$/,
                message: "Must be an email address or an 11 digit number.",
              },
            }}
            control={control}
            name="username"
            label={"Username"}
          />

          <FormInputText
            rules={{
              required: true,
              minLength: {
                value: 8,
                message: "Must be 8 or more characters",
              },
              pattern: {
                value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                message:
                  "Must be 8 or more characters and contains at least 1 number and 1 letter.",
              },
            }}
            type="password"
            control={control}
            name="password"
            label={"Password"}
          />

          <div></div>

          <CustomButton
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
            text={"Login"}
          />
        </form>

        {error && <Alert severity="error">{error}</Alert>}
      </div>
    </div>
  );
};

export default Login;
