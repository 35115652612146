import React, { useEffect, useState } from "react";
import {  GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";

import TableList from "../../Components/Table/Table";
import { MenuItem, Select, selectClasses } from "@mui/material";
import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa";
import CustomerList from "../CustomerList/CustomerList";
import OrderTransactionList from "../OrderTransactionList/OrderTransactionList";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { ReviewStatus } from "../../Data/Enums";
import ReviewReview from "../../Components/ReviewReview/ReviewReview";
import ProductPopup from "../../Components/ProductPopup/ProductPopup";




const ReviewList: React.FunctionComponent = () => {
  const [reviews, setReviews] = useState([]);
  const getReviewsClient = useHttpClient();
  const updateAdminStatusClient = useHttpClient();
  const [count, setCount] = useState(0);
  const [pageInd, setPageInd] = useState(0);
  const [view, setView] = useState(0);
  const [status, setStatus] = useState(0);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedReviewId, setSelectedReviewId] = useState("");
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [oenProductModal, setOpenProductModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");
  const dataGridApiRef = useGridApiRef();

  let reviewStatuses = [
    { name: ReviewStatus.Pending, 
      vendors: [], 
    },
    {
      name: ReviewStatus.Accepted,
      vendors: [],
    },
    {
      name: ReviewStatus.Rejected,
      vendors: [],
    },
  ];


  let columns: GridColDef[] = [
  
    {
      field: "reviewNumber",
      headerName: "Review Number",
      width: 150,
      
    },
    {
      field: "user",
      headerName: "User Id",
      width: 250,
      editable: false,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
      editable: true,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 100,
      editable: true,
    },
    {
      field: "product",
      headerName: "Product",
      width: 250,
      editable: false,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            console.log(params.row)
            setOpenProductModal(true);
            setSelectedProductId(params.row.product);
          }}
        >
          {params.row.product}
        </div>
      ),
      
    },
    
    {
      field: "date",
      headerName:"Date Created",
      width:100,
      editable:true,
      renderCell: (params) =>{
        return<div>{new Date(params.row.createdAt).toLocaleDateString()}</div> ;
      },
    },
    {
      field: "Review",
      headerName: "Review",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
        onClick={() => {
          setSelectedReview(params.row);
          setSelectedReviewId(params.row.id);
          setOpenReviewModal(true);
        }}
        
        />
      ),
    },

    
  ];

 

  const getReviews = async (page?:number, adminStatusUpdated?: boolean) => {

    try {
      if(page){
        setPageInd(page)
      }

      // const currentPage = dataGridApiRef.current.state.pagination.paginationModel.page;
      // const skipAmount = adminStatusUpdated ? currentPage * 10 : reviews.length;

      if (adminStatusUpdated) {
        setReviews([]);
      }

      console.log("PAGE NoWW is ", pageInd);
      
      const response = await getReviewsClient.sendRequest({
        url: `/owner/reviews/${reviewStatuses[status].name}?pageIndex=${page?page:1}`,
        method: "get",
        body:null,
        headers: {
          headers: { "Content-Type": "application/json" },
        },
      });
      console.log(response.data);
      
      if (response && response.data) {
        setReviews(response.data);
        setCount(response.count);
        console.log(reviews);
        
      }
    } catch (e) {
      console.log(getReviewsClient.error);
    }
  };

  

  useEffect(() => {
    getReviews();
  },[status, openReviewModal]);

  return (
    <div className="page">
  

      <SnackbarErrorClient client={getReviewsClient} />
      {openReviewModal && ( // Ensure openLocationModal is defined before rendering ProductPopup
        <ReviewReview
          review={selectedReview}
          reviewId={selectedReviewId}
          open={openReviewModal}
          setOpen={setOpenReviewModal}
        />
      )}

{oenProductModal && ( // Ensure openLocationModal is defined before rendering ProductPopup
        <ProductPopup
          productId={selectedProductId}
          open={oenProductModal}
          setOpen={setOpenProductModal}
        />
      )}


      <div
            style={{
              display: "flex",
              gap: "16px",
              alignItems: "center",
              justifyContent:"flex-start",
            }}
          >

            <p style={{ marginBottom: 0 }} className="pageTitle">
            </p>
      </div>
      <div className="pageUpper">
        <p className="pageTitle">Reviews</p>
        <div className="pageUpperCTAs">
          {reviewStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setReviews([]);
                setStatus(i);
                dataGridApiRef.current.setPage(0);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      </div>
        
          <TableList
          key={status.toString()}
        rows={reviews}
        paginationMode = {true}
        isLoading={getReviewsClient.isLoading || updateAdminStatusClient.isLoading}
        columns={columns}
        rowsCount={count}
        getItems={(p) => getReviews(p)}
        dataGridApiRef={dataGridApiRef}
        />
        
      
      
    </div>

  );
};

export default ReviewList;
