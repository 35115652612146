import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import "../SearchBar/SearchBar.scss";
import { SearchByType } from "../../Data/Enums";

interface SearchBarProps {
  fetchDataById: (id: string) => Promise<void>;
  toggleSearchBarList: () => void;
  fetchDataByName?: (name: string) => Promise<void>;
  customPlaceholderText?: string;
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [searchBy, setSearchBy] = useState<SearchByType>("id");

  const handleSearch = async () => {
    if (searchBy === "id") {
      await props.fetchDataById(id);
    }
    else if (searchBy === "name" && props.fetchDataByName) {
      await props.fetchDataByName(name)
    }
    props.toggleSearchBarList();
  };


  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
        <TextField
          // label={props.customPlaceholderText || "Enter ID..."}
          label={searchBy==="id" ? "Enter ID..." : searchBy==="name" ? "Enter Name..." : "Enter ID..."}
          variant="outlined"
          value={searchBy==="id" ? id : searchBy==="name" ? name : id}
          onChange={(e) => {
            searchBy  === "name" ? setName(e.target.value) : setId(e.target.value)
          }}
          style={{width: "300px"}}
        />
        <Button variant="contained" onClick={handleSearch} style={{ height: "56px", marginLeft: "5px" }}>
          Search
        </Button>
        {props.fetchDataByName && (
          <Select
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value as SearchByType)}
          style={{ marginLeft: "5px" }}
        >
          <MenuItem value={"id"}>Search by ID</MenuItem>
          <MenuItem value={"name"}>Search by Name</MenuItem>
        </Select>
      )}
    </div>
  );
};

export default SearchBar;
