import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaEye } from "react-icons/fa";

import { IProvince } from "../../Models/IProvince";
import TableList from "../../Components/Table/Table";
import CitiesList from "./CitiesList";

interface ProvincesListProps {}

const ProvincesList: React.FunctionComponent<ProvincesListProps> = () => {
  const [provinces, setProvinces] = useState<IProvince[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<IProvince | null>(
    null
  );
  const getProvincesClient = useHttpClient();
  

  useEffect(() => {
    getProvinces();
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "nameAr",
      headerName: "NameAr",
      width: 200,
      editable: true,
    },
    {
      field: "children",
      headerName: "View Cities",
      width: 120,
      renderCell: (params) => (
        <FaEye
          onClick={() => {
            console.log("hi");
            
            setSelectedProvince(params.row);
            console.log(selectedProvince);
            
            
          }}
        />
      ),
    }
  ];


  const getProvinces = async () => {
    try {
      const response = await getProvincesClient.sendRequest({
        url: "/owner/province",
        method: "get",
        body: null,
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log(response);
      if (response) {
        setProvinces(response);
      }
    } catch (e) {
      console.log(e);
      console.log(getProvincesClient.error);
    }
  };

  


  return (
    selectedProvince == null?

    <div className="page">
      <SnackbarErrorClient client={getProvincesClient} />

      

      <div className="pageUpper">
        
          <p className="pageTitle">Provinces</p>
        
      </div>

      <TableList
        rows={provinces}
        isLoading={getProvincesClient.isLoading}
        columns={columns}
      />
    </div>
    :
    <CitiesList
      province={selectedProvince}
    />
    
  );
};

export default ProvincesList;
