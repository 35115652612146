import React from "react";
import { Dialog, DialogContent } from "@mui/material";

interface MultipleImagePopupProps {
  imageSrcs: string[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultipleImagePopup: React.FC<MultipleImagePopupProps> = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          height: "60%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {props.imageSrcs.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`image-${index}`}
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                objectFit: "cover",
              }}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MultipleImagePopup;
