import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { primaryColor } from "../Shared/globalVar";
import { Backdrop, CircularProgress } from "@mui/material";
import "./PageLoader.scss"

interface PageLoaderProps {
  loading: boolean;
}

const PageLoader: React.FunctionComponent<PageLoaderProps> = (props) => {
  return <>
  <Backdrop
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
    open={props.loading}
    // onClick={handleClose}
  >
    <CircularProgress color="inherit" />
  </Backdrop>

  {/* <CircleSpinnerOverlay loading={props.loading} color={primaryColor} /> */}
  </> 
};

export default PageLoader;
