import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaEdit, FaTrash } from "react-icons/fa";

import { IDiscount } from "../../Models/IDiscount";
import AddUpdatePromocode from "../../Components/AddUpdatePromocode/AddUpdatePromocode";
import TableList from "../../Components/Table/Table";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";

interface PromocodeListProps {}

const PromocodeList: React.FunctionComponent<PromocodeListProps> = () => {
  const [discounts, setDiscounts] = useState<IDiscount[]>([]);

  const getPromocodesClient = useHttpClient();
  const [openAddUpdateModal, setOpenAddUpdateModal] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<IDiscount | null>(null);
  const deleteDiscountClient = useHttpClient();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedDiscountToDelete, setSelectedDiscountToDelete] = useState<IDiscount | null>(null);

  useEffect(() => {
    getPromocodes();
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
      editable: true,
    },
    {
      field: "isPercentage",
      headerName: "Is Percentage?",
      width: 200,
      editable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      editable: true,
    },
    {
      field: "maxDiscountAmount",
      headerName: "Max Discount Amount",
      width: 200,
      editable: true,
    },
    {
      field: "onVendor",
      headerName: "On Vendor",
      width: 200,
      editable: true,
    },
    {
      field: "update",
      headerName: "Update",
      width: 120,
      renderCell: (params) => (
        <FaEdit
          onClick={() => {
            setSelectedDiscount(params.row);
            setOpenAddUpdateModal(true);
          }}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            setSelectedDiscountToDelete(params.row);
            setOpenDeletePopup(true);
          }}
        />
      ),
    },
  ];

  const getPromocodes = async () => {
    try {
      const response = await getPromocodesClient.sendRequest({
        url: "/owner/discount/get",
        method: "post",
        body: {
          type: "promocode",
        },
        headers: {
          headers: { "Content-Type": "application/json" },
        },
      });
      console.log(response);
      if (response) {
        setDiscounts(response);
      }
    } catch (e) {
      console.log(getPromocodesClient.error);
    }
  };
  
  const deleteDiscount = async() => {
    try {
      const id = selectedDiscountToDelete?.id;

      const response = await deleteDiscountClient.sendRequest({
        url: "/owner/discount/delete",
        method: "post",
        body: {
          id: id
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      if (response) {
        console.log(response);
      }
      setDiscounts([]);
      await getPromocodes();
    } catch (e) {
      console.log(deleteDiscountClient.error);
    }
  };

  return (
    <div className="page">
      <SnackbarErrorClient client={getPromocodesClient} />
     
      <AddUpdatePromocode
        open={openAddUpdateModal}
        discount={selectedDiscount}
        
        setOpen={setOpenAddUpdateModal}
        onSubmit={() => {
          setSelectedDiscount(null);
          getPromocodes();
          
        }}
      />
      
      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteDiscount}
        deleteHttpClient={deleteDiscountClient}
      />

      <div className="pageUpper">
        <p className="pageTitle">Promocodes</p>

        <CustomButton
          text="Add Promocode"
          onClick={() => {
            setOpenAddUpdateModal(true);
          }}
          isSecondary={true}
        />
      </div>

      <TableList
        rows={discounts}
        isLoading={getPromocodesClient.isLoading}
        columns={columns}
      />

  
    </div>
  );
};

export default PromocodeList;
