import { Alert, Backdrop, Box, CircularProgress, Modal, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { IProduct } from "../../Models/IProduct";
import FormInputText from "../FormInputText/FormInputText";
import { useForm } from "react-hook-form";
import "./ReviewProduct.scss";
import CustomButton from "../CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import PageLoader from "../PageLoader";

interface ReviewProductProps {
  product?: IProduct | null;
  open: any,
  setOpen: any,
  getProducts: any
}

const ReviewProduct: React.FunctionComponent<ReviewProductProps> = (props) => {
  // const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => props.setOpen(false);

  const reviewProductClient = useHttpClient();

  let defValues = {};

  useEffect(() => {
    setError("");


    if (props.product) {
      console.log(props.product);
      defValues = {
        id: props.product.id ?? "",
        name: props.product ? props.product.name ?? "" : "",
        price: props.product ? props.product.price ?? null : null,
        description: props.product ? props.product.description ?? "" : "",
        categoryName: props.product ? props.product.categoryName ?? "" : "",
        gender: props.product ? props.product.gender ?? "" : "",
        forKids: props.product ? props.product.forKids ?? false : false,
        sku: props.product ? props.product.sku ?? "" : "",
        reason: "",
      };

      reset(defValues);
    } else {
    }
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const reviewProduct = async (id: any, decision: any) => {
    setError("");
    const reason = control._formValues.reason;

    console.log( "REASON" + reason +  "REASON")

    if (decision === "reject" && (!reason || reason === " ")) {
      setError("Reason required");

      console.log('reson stuff')

      return;
    }

    try {
      const response = await reviewProductClient.sendRequest({
        url: "/owner/product/review",
        method: "post",
        body: {
          id,
          decision,
          reason
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log(response);
      props.setOpen(false);
      props.getProducts(true, true);
    } catch (e) {
      console.log(reviewProductClient.error);
    }
  };

  return (
    <div>
      <SnackbarErrorClient client={reviewProductClient} />
    
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox" >
          <p className="pageTitle">
            Review Product
          </p>

          {props.product && (
            <form className="reviewProduct">
              <PageLoader loading={reviewProductClient.isLoading} />

              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="id"
                label={"Id"}
              />
              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="name"
                label={"Name"}
              />
              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="price"
                label={"price"}
              />
              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="description"
                label={"description"}
                multiline={true}
              />
              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="categoryName"
                label={"categoryName"}
              />
              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="gender"
                label={"gender"}
              />

              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="forKids"
                label={"forKids"}
              />

              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="sku"
                label={"sku"}
              />

              <FormInputText
                rules={{}}
                control={control}
                name="reason"
                label={"reason"}
              />
              {error && <Alert severity="error">{error}</Alert>}

              {props.product && props.product.images && props.product.images.length > 0 && (
                <div className="container">
                  {props.product.images.map((object, i) => (
                    <img className="productImage" src={object.toString()} />
                  ))}
                </div>
              )}

              <CustomButton
                text={"Accept"}
                onClick={() => reviewProduct(props.product?.id, "accept")}
              />

              <CustomButton
                text={"Reject"}
                isSecondary={true}
                onClick={() => reviewProduct(props.product?.id, "reject")}
              />
            </form>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ReviewProduct;
