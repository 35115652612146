import { Alert, Backdrop, Box, CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { IProduct } from "../../Models/IProduct";
import FormInputText from "../FormInputText/FormInputText";
import { useForm } from "react-hook-form";
import "../ReviewProduct/ReviewProduct.scss";

import CustomButton from "../CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import PageLoader from "../PageLoader";
import { IVendor } from "../../Models/IVendor";
import CustomFormInputList from "../CustomFormInputList/CustomFormInputList";
import { handleRemoveItem, handleAddItem } from "../CustomFormInputList/CustomFormInputListUtils";

interface ReviewVendorProps {
  vendor?: IVendor | null;
  open: any,
  setOpen: any,
  getVendors: any
  editing?: boolean
  setEditing?: any
}

const ReviewVendor: React.FunctionComponent<ReviewVendorProps> = (props) => {
  // const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [newCategory, setNewCategory] = useState("");
  
  const handleClose = () => {
    props.setOpen(false);
    props.setEditing(false);
  }

  const reviewVendorClient = useHttpClient();
  
  
  let defValues = {};

  useEffect(() => {
    setError("");
    setCategories([]);

    if (props.vendor) {
      console.log("PROPS VENDOR");
      console.log(props.vendor);

      defValues = {
        id: props.vendor.id ?? "",
        name: props.vendor ? props.vendor.name ?? "" : "",
        reason: "",
        categoryName: props.vendor.categories? props.vendor.categories.map(cat=>cat.name).join(", ")?? "" : "" //add description and category
      };

      reset(defValues);
    } else {
    }

    if (props.vendor?.categories) {
      let categoryIds: any = [];
      props.vendor.categories.forEach(category => {
        categoryIds.push(category._id);
      })
      setCategories(categoryIds || []);
    }
    
    
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const reviewVendor = async (id: any, decision: any) => {
    setError("");
    const reason = control._formValues.reason;

    console.log( "REASON" + reason +  "REASON")

    if (decision === "reject" && (!reason || reason === " ")) {
      setError("Reason required");

      console.log('reson stuff')

      return;
    }

    try {
      const response = await reviewVendorClient.sendRequest({
        url: "/owner/vendor/review",
        method: "post",
        body: {
          id,
          decision,
          reason
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log(response);
      props.setOpen(false);
      props.getVendors(true,true);
    } catch (e) {
      console.log(reviewVendorClient.error);
    }
  };

  const editVendor = async () => {
    const values = control._formValues;

    try {
      const response = await reviewVendorClient.sendRequest({
        url: "/owner/vendor/edit",
        method: "post",
        body: {
          id: props.vendor?._id,
          name: values.name,
          categories: JSON.stringify(categories)
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log(response);
      props.setOpen(false);
      props.getVendors(true,true);
    } catch (e) {
      console.log(reviewVendorClient.error);
    }
  }

  return (
    <div>
      <SnackbarErrorClient client={reviewVendorClient} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.editing ? "Edit" : "Review "} Vendor
          </p>

          {props.vendor && (
            <form className="reviewProduct">
              <PageLoader loading={reviewVendorClient.isLoading} />

              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="id"
                label={"Id"}
              />
              <FormInputText
                rules={{}}
                disabled={props.editing ? false : true}
                control={control}
                name="name"
                label={"Name"}
              />
              {/* <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="price"
                label={"price"}
              /> */}
              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="description"
                label={"description"}
              />
              <FormInputText
                rules={{}}
                disabled={props.editing ? false : true}
                control={control}
                name="categoryName"
                label={"category name"}
              />
              {/* <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="gender"
                label={"gender"}
              />

              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="forKids"
                label={"forKids"}
              />

              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="sku"
                label={"sku"}
              /> */}

              <FormInputText
                rules={{}}
                control={control}
                name="reason"
                label={"reason"}
              />

            {props.editing && (
              <CustomFormInputList
                title="Categories"
                items={categories}
                newItem={newCategory}
                onNewItemChange={setNewCategory}
                onAddItem={() => {handleAddItem(newCategory, setNewCategory, categories, setCategories)}}
                onRemoveItem={(index) => handleRemoveItem(index, categories, setCategories)}
              />
            )}

              {error && <Alert severity="error">{error}</Alert>}

              {!props.editing && (
                <>
                  <CustomButton
                    text={"Accept"}
                    onClick={() => reviewVendor(props.vendor?.id, "accept")}
                  />

                  <CustomButton
                    text={"Reject"}
                    isSecondary={true}
                    onClick={() => reviewVendor(props.vendor?.id, "reject")}
                  />
                </>
              )}

              {props.editing && (
                <CustomButton
                  text={"Edit"}
                  onClick={() => editVendor()}
                />
              )}
            </form>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ReviewVendor;
