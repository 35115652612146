import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import TableList from "../../Components/Table/Table";
import CustomButton from "../../Components/CustomButton/CustomButton";
import AddUpdateAdSection from "../../Components/AddUpdateAdSection/AddUpdateAdSection";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IAdSection } from "../../Models/IAdSection";
import { MenuItem, Select } from "@mui/material";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";
// import { IMarketingCampaign } from "../../Models/IMarketingCampaign";

interface AdSectionListProps {}

const AdSectionList: React.FunctionComponent<AdSectionListProps> = () => {
  const [AdSections, setAdSections] = useState</*IMarketingCampaign*/ []>([]);

  const getAdSectionsClient = useHttpClient();
  const [openAddUpdateModal, setOpenAddUpdateModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const [selectedAdSection, setSelectedAdSection] = useState<IAdSection | null>(null);
  const deleteAdSectionClient = useHttpClient();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedAdSectionToDelete, setSelectedAdSectionToDelete] = useState<IAdSection | null>(null);


  useEffect(() => {
    if (openAddUpdateModal === false) {
      setSelectedAdSection(null);
    }
  }, [openAddUpdateModal, setOpenAddUpdateModal]);

  useEffect(() => {
    getAdSections();
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "Image",
      headerName: "Image",
      width: 90,
      renderCell: (params) =>
        params.row.image && (
          <img
            width={120}
            height={90}
            src={params.row.image}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.image);
            }}
          />
        ),
    },

    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "nameAr",
      headerName: "Name AR",
      width: 100,
      editable: true,
    },
    {
      field: "product",
      headerName: "Product ID",
      width: 150,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category ID",
      width: 150,
      editable: true,
    },
    {
      field: "vendor",
      headerName: "Vendor ID",
      width: 150,
      editable: true,
    },
    {
      field: "discount",
      headerName: "Discount ID",
      width: 150,
      editable: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      renderCell: (params) => (
        <FaEdit
          onClick={() => {
            setSelectedAdSection(params.row);

            setOpenAddUpdateModal(true);
          }}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.row.isActive}
          onChange={async (e) => {
            console.log(
              `Updating active status. New status: ${e.target.value}`
            );
            await updateActiveStatus(params.row.id, e.target.value === "true")
            await getAdSections();
          }}
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            setSelectedAdSectionToDelete(params.row);
            setOpenDeletePopup(true);
          }}
        />
      ),
    },
  ];

  const getAdSections = async () => {
    try {
      const response = await getAdSectionsClient.sendRequest({
        url: "/owner/homesection/ad",
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
      if (response) {
        const responseWithIds = response.map((item: any) => {
          return { ...item, id: item._id };
        });
        console.log(responseWithIds);
        setAdSections(responseWithIds);
      }
    } catch (e) {
      console.log(e);
      console.log(getAdSectionsClient.error);
    }
  };

  const updateActiveStatus = async (id: any, isActive: any) => {

    try {
      const response = await getAdSectionsClient.sendRequest({
        url: "/owner/homesection/isactive",
        method: "put",
        body: { id, isActive, sectionType: "ad" },
        // body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);

    } catch (e) {
      console.log(e);
      console.log(getAdSectionsClient.error);
    }
  };
  
  const deleteAdSection = async() => {
    try {
      const id = selectedAdSectionToDelete?.id;

      const response = await deleteAdSectionClient.sendRequest({
        url: "/owner/homesection/ad/delete",
        method: "post",
        body: {
          id: id
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      if (response) {
        console.log(response);
      }
      setAdSections([]);
      await getAdSections();
    } catch (e) {
      console.log(deleteAdSectionClient.error);
    }
  };


  return (
    <div className="page">
      <SnackbarErrorClient client={getAdSectionsClient} />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteAdSection}
        deleteHttpClient={deleteAdSectionClient}
      />

      <AddUpdateAdSection
        open={openAddUpdateModal}
        adSection={selectedAdSection}
        setOpen={setOpenAddUpdateModal}
        getAdSections={getAdSections}
      />

      <div className="pageUpper">
        <p className="pageTitle">Ad Sections</p>
        <CustomButton
          text="Add Ad Section"
          onClick={() => {
            setOpenAddUpdateModal(true);
          }}
          isSecondary={true}
        />
      </div>

      <TableList
        rows={AdSections}
        isLoading={getAdSectionsClient.isLoading}
        columns={columns}
      />
    </div>
  );
};

export default AdSectionList;
