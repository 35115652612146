import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { VendorStatus } from "../../Data/Enums";
import ReviewVendor from "../../Components/ReviewVendor/ReviewVendor";
import TableList from "../../Components/Table/Table";
import { FaEdit, FaExternalLinkAlt } from "react-icons/fa";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import formatDate from "../../Utils/formatDate";
import SearchBarList from "../../Components/SearchBarList/SearchBarList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { IVendor } from "../../Models/IVendor";
import ProductList from "../ProductList/ProductList";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";

interface VendorListProps {}

const VendorList: React.FunctionComponent<VendorListProps> = () => {
  const [status, setStatus] = useState(0);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const getVendorClient = useHttpClient();
  const [openReview, setOpenReview] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const searchBarClient = useHttpClient();
  const [searchBarResults, setSearchBarResults] = useState<any[]>([]);
  const [displaySearchBarResults, setDisplaySearchBarResults] = useState(false);
  const [selectedVendorProductsId, setSelectedVendorProductsId] = useState<
    string | null
  >(null);
  const dataGridApiRef = useGridApiRef();
  const [editingVendor, setEditingVendor] = useState(false);
  const getVendorLocationClient = useHttpClient();
  const [openVendorLocationModal, setOpenVendorLocationModal] = useState(false);
  const [vendorLocationData, setVendorLocationData] = useState({});
  const [displayProductsByVendor, setDisplayProductsByVendor] = useState(false);

  let vendorStatuses = [
    { name: VendorStatus.Pending, vendors: [], needsLoading: true, count: 0 },
    {
      name: VendorStatus.Accepted,
      vendors: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: VendorStatus.Rejected,
      vendors: [],
      needsLoading: true,
      count: 0,
    },
  ];
  const columns: GridColDef[] = [
    {
      field: "logo",
      headerName: "Logo",
      width: 90,
      renderCell: (params) =>
        params.row.logo && (
          <img
            width={120}
            height={90}
            src={params.row.logo}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.logo);
            }}
          />
        ),
    },
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
      editable: true,
    },
    {
      field: "products",
      headerName: "Products",
      width: 80,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setSelectedVendorProductsId(params.row.id);
            console.log(`selected vendor products: ` + params.row.id);
            setDisplayProductsByVendor(true);
          }}
        />
      ),
    },
    {
      field: "review",
      headerName: "Review",
      width: 60,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            handleEvent(params);
          }}
        />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setEditingVendor(true);
            setOpenReview(true);
            setSelectedVendor(params.row);
          }}
        />
      ),
    },
    {
      field: "location",
      headerName: "Location",
      width: 70,
      renderCell: (params) => (
        <FaExternalLinkAlt
        onClick={async () => {
          setVendorLocationData({});
          await getVendorLocation(params.row.location);
          setOpenVendorLocationModal(true);
        }}
        
        />
      ),
    },
    {
      field: "otherPhoneNumber",
      headerName: "Other Phone Number",
      width: 130,
      editable: true,
    },
    {
      field: "socialMediaLink",
      headerName: "Social Media Link",
      width: 200,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 170,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
  ];

  // const displayOriginalComponent = () => {
  //   setSelectedVendorProductsId(null);
  // };

  const getVendors = async (
    getCount: boolean,
    adminStatusUpdated?: boolean
  ) => {
    setSelectedVendor(null);

    const currentPage =
      dataGridApiRef.current.state.pagination.paginationModel.page;

    const skipAmount = adminStatusUpdated ? currentPage * 10 : vendors.length;

    try {
      const response = await getVendorClient.sendRequest({
        url: `/owner/vendor/get?skip=${skipAmount}&descending=true`,
        method: "post",
        body: {
          status: vendorStatuses[status].name,
          getCount: getCount,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
        },
      });
      if (response && response.vendors) {

        console.log(response);

        vendorStatuses[status].vendors = response.vendors;

        if (getCount) {
          setCurrentCount(response.count);
        }

        // const allVendors = [...vendors, ...vendorStatuses[status].vendors];

        let allVendors;

        if (adminStatusUpdated) {
          allVendors = [...vendorStatuses[status].vendors];
        } else {
          allVendors = [...vendors, ...vendorStatuses[status].vendors];
        }

        setVendors(allVendors);
      }
    } catch (e) {
      console.log(getVendorClient.error);
    }
  };

  const getVendorById = async (vendorId: string) => {
    try {
      const response = await searchBarClient.sendRequest({
        url: "/owner/vendor/getById",
        method: "post",
        body: { id: vendorId },
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
      if (response) {
        console.log("fetching by ID");
        console.log(response);
        setSearchBarResults([response.vendor]);
        console.log(searchBarResults);
      }
    } catch (e) {
      console.log(e);
      console.log(searchBarClient.error);
    }
  };

  const getVendorByName = async (vendorName: string) => {
    try {
      const response = await searchBarClient.sendRequest({
        url: "/owner/vendor/getByName",
        method: "post",
        body: { name: vendorName },
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
      if (response) {
        console.log("fetching by name");
        console.log(response);
        setSearchBarResults([response.vendor]);
        console.log(searchBarResults);
      }
    } catch (e) {
      console.log(e);
      console.log(searchBarClient.error);
    }
  };

  const getVendorLocation = async (locationId: String) => {
    try {
      const response = await getVendorLocationClient.sendRequest({
        url: "/owner/vendorlocation/get/id",
        method: "post",
        body: {
          locationId: locationId,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });

      console.log(`Fetching location data for vendor with ID ${locationId}`);
      
      console.log(response);

      if (response) {

        let modifiedData = {
          "Street": response.street ?? "",
          "Province": response.provinceName ?? "",
          "Province AR": response.provinceNameAr ?? "",
          "City": response.cityName ?? "",
          "City AR": response.cityNameAr ?? "",
          "Building": response.building ?? "",
          "Floor": response.floor ?? "",
          "Apartment": response.apartment ?? "",
          "City ABS ID": response.cityAbsCityId ?? "",
          "City Location Code": response.cityLocationCode ?? "",
          "City Runner ID": response.cityRunnerId ?? "",
          "Latitude": response.latitude ?? "",
          "Longitude": response.longitude ?? "",
        }

        setVendorLocationData(modifiedData);

      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendors(true);
  }, [status]);

  const handleEvent = (data: any) => {
    console.log(data);
    console.log(data.row);

    setTimeout(() => {
      setOpenReview(true);
      setSelectedVendor(data.row);
    }, 50);
  };

  return displaySearchBarResults ? (
    // show search bar results

    <SearchBarList
      toggleSearchBarList={() =>
        setDisplaySearchBarResults(!displaySearchBarResults)
      }
      columns={columns}
      rows={searchBarResults}
      isLoading={searchBarClient.isLoading}
    />
    
  ) : displayProductsByVendor ? (
    // show the products page filtered by vendor

    <ProductList
      vendorId={selectedVendorProductsId}
      returnToOriginalComponent={() => {
        setDisplayProductsByVendor(false);
        setSelectedVendorProductsId(null);
      }}
    />
    
  ) : (
    // otherwise, show the main Vendors page

    <div className="page">
      <SnackbarErrorClient client={getVendorClient} />

      <ReviewVendor
        getVendors={getVendors}
        open={openReview}
        setOpen={setOpenReview}
        vendor={selectedVendor}
        editing={editingVendor}
        setEditing={setEditingVendor}
      />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <GenericPopup
        data={vendorLocationData}
        open={openVendorLocationModal}
        setOpen={setOpenVendorLocationModal}
      />

      <div className="pageUpper">
        <p className="pageTitle">Vendors</p>
        <div className="pageUpperCTAs">
          {vendorStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setCurrentCount(0);
                setVendors([]);
                setStatus(i);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      </div>

      <SearchBar
        fetchDataById={getVendorById}
        fetchDataByName={getVendorByName}
        toggleSearchBarList={() =>
          setDisplaySearchBarResults(!displaySearchBarResults)
        }
      />
      <TableList
        // onRowClick={handleEvent}
        rows={vendors}
        isLoading={getVendorClient.isLoading}
        columns={columns}
        rowsCount={currentCount}
        getItems={() => getVendors(false)}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />
    </div>
  );
};

export default VendorList;
