import axios from "../Api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } : any = useAuth();

    const logout = async () => {
        setAuth({});
        try {
            const response = await axios('/owneruser/logout', {
                withCredentials: true
            });

            console.log(response)
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout