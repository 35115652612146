import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaExternalLinkAlt, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TableList from "../../Components/Table/Table";

import { MenuItem, Select, Snackbar } from "@mui/material";
import OrderList from "../OrderList/OrderList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import SearchBarList from "../../Components/SearchBarList/SearchBarList";

interface OrderTransactionListProps {}

const OrderTransactionList: React.FunctionComponent<
  OrderTransactionListProps
> = (props: OrderTransactionListProps) => {
  const [orderTransactions, setOrderTransactions] = useState([]);
  const getOrderTransactionsClient = useHttpClient();
  const [transactionId, setTransactionId] = useState(null);

  const searchBarClient = useHttpClient();
  const [searchBarResults, setSearchBarResults] = useState([]);
  const [displaySearchBarResults, setDisplaySearchBarResults] = useState(false);
  const [displayOrdersByOrderTransaction, setDisplayOrdersByOrderTransaction] =
    useState(false);

  useEffect(() => {
    getOrderTransactions();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "_id",
      headerName: "Order Transaction ID",
      width: 250,
      editable: false,
    },
    {
      field: "customerUser",
      headerName: "Customer ID",
      width: 250,
      editable: false,
    },
    // {
    //   field: "statusHistory",
    //   headerName: "Status History",
    //   width: 250,
    //   editable: false,
    // },
    {
      field: "currentStatus",
      headerName: "Current Status",
      width: 120,
      editable: false,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 100,
      editable: false,
    },
    {
      field: "subtotalAmount",
      headerName: "Subtotal Amount",
      width: 150,
      editable: false,
    },
    {
      field: "deliveryFees",
      headerName: "Delivery Fees",
      width: 150,
      editable: false,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 150,
      editable: false,
    },
    {
      field: "orderItems",
      headerName: "View Order Items",
      width: 150,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            console.log(params);
            setTransactionId(params.row._id);
            setDisplayOrdersByOrderTransaction(true);
          }}
        />
      ),
    },
  ];

  const getOrderTransactions = async () => {
    try {
      const response = await getOrderTransactionsClient.sendRequest({
        url: "/owner/ordertransaction",
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      });
      console.log(response);
      if (response) {
        setOrderTransactions(response);
      }
    } catch (e) {
      console.log(e);
      console.log(getOrderTransactionsClient.error);
    }
  };

  const getOrderTransactionsById = async (orderTransactionId: string) => {
    try {
      const response = await getOrderTransactionsClient.sendRequest({
        url: `/owner/ordertransaction/getbyid?orderTransactionId=${orderTransactionId}`,
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      });
      console.log("fetched order transaction by ID");
      console.log(response);
      if (response) {
        setSearchBarResults(response);
      }
    } catch (e) {
      console.log(e);
      console.log(getOrderTransactionsClient.error);
    }
  };

  return displaySearchBarResults ? (
    // show search bar results

    <SearchBarList
      toggleSearchBarList={() =>
        setDisplaySearchBarResults(!displaySearchBarResults)
      }
      columns={columns}
      rows={searchBarResults}
      isLoading={searchBarClient.isLoading}
    />

  ) : displayOrdersByOrderTransaction ? (
    // show the orders page filtered by order transaction

    <OrderList
      canChangeStatus={false}
      transactionId={transactionId}
      returnToOriginalComponent={() => {
        setDisplayOrdersByOrderTransaction(false);
      }}
    />

  ) : (
    // otherwise, show the main OrderTransactions page

    <div className="page">
      <SnackbarErrorClient client={getOrderTransactionsClient} />

      <div className="pageUpper">
        <p className="pageTitle">Order Transactions</p>
      </div>

      <SearchBar
        fetchDataById={getOrderTransactionsById}
        toggleSearchBarList={() =>
          setDisplaySearchBarResults(!displaySearchBarResults)
        }
      />

      <TableList
        rows={orderTransactions}
        isLoading={getOrderTransactionsClient.isLoading}
        columns={columns}
        showRowNumbersNonPaginated
        rowNumbersDescending
      />
    </div>
  );
};

export default OrderTransactionList;
