import React, { useEffect, useMemo, useState } from "react";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import {
  Alert,
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useHttpClient } from "../../Hooks/HttpHook";
import { ICustomer } from "../../Models/ICustomer";
import { ILocation } from "../../Models/ILocation";
import { IProvince } from "../../Models/IProvince";
import { ICity } from "../../Models/ICity";
import CustomerList from "../../Pages/CustomerList/CustomerList";
import PageLoader from "../PageLoader";

interface GenericPopupProps {
  data: { [key: string]: any };
  // rowNames: String[];
  // rowValues: String[]
  //   province?: IProvince | null;
  //   city?: ICity | null;
  open: any;
  setOpen: any;
}

const GenericPopup: React.FunctionComponent<GenericPopupProps> = (props) => {
  const handleClose = () => props.setOpen(false);
  const httpClient = useHttpClient();

  // const data: any = {

  // };

  // for (let i = 0; i < props.rowNames.length; i++) {
  //   data.props.rowNames[i] = props.rowValues[i];
  // }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <PageLoader loading={httpClient.isLoading} />

          <Table>
            <TableBody>
              {Object.entries(props.data).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value?.toString() ?? ""}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Modal>
    </div>
  );
};

export default GenericPopup;
