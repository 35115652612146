import React from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

interface SectionTypeSelectorProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SectionTypeSelector: React.FC<SectionTypeSelectorProps> = ({
  value,
  onChange,
}) => {
  return (
    <RadioGroup
      row
      aria-label="section-type"
      name="section-type"
      value={value}
      onChange={onChange}
    >
      <FormControlLabel
        value="products"
        control={<Radio color="primary" />}
        label="Products"
        labelPlacement="end"
      />
      <FormControlLabel
        value="vendors"
        control={<Radio color="primary" />}
        label="Vendors"
        labelPlacement="end"
      />
    </RadioGroup>
  );
};

export default SectionTypeSelector;
