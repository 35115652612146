import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaEye, FaExternalLinkAlt } from "react-icons/fa";

import { defaultMaxListeners } from "events";
import { ICustomer } from "../../Models/ICustomer";
import TableList from "../../Components/Table/Table";
import CustomerLocationPopup from "../../Components/CustomerLocationPopup/CustomerLocationPopup";
import { ILocation } from "../../Models/ILocation";
import OrderList from "../OrderList/OrderList";
import { IProvince } from "../../Models/IProvince";
import { ICity } from "../../Models/ICity";
import { CollectionsBookmarkRounded } from "@mui/icons-material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import SearchBarList from "../../Components/SearchBarList/SearchBarList";

interface CustomerListProps {}

const CustomerList: React.FunctionComponent<CustomerListProps> = () => {
  const [customers, setCustomers] = useState<ICustomer[]>([]);

  const getCustomersClient = useHttpClient();
  const getCustomerLocationClient = useHttpClient();
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [customerPurchaseHistoryId, setCustomerPurchaseHistoryId] =
    useState(null);
  const [customerLocation, setCustomerLocation] = useState<ILocation>();
  const [province, setProvince] = useState<IProvince>();
  const [city, setCity] = useState<ICity>();

  const searchBarClient = useHttpClient();
  const [searchBarResults, setSearchBarResults] = useState([]);
  const [displaySearchBarResults, setDisplaySearchBarResults] = useState(false);
  const [displayOrdersByCustomer, setDisplayOrdersByCustomer] = useState(false);

  const getCustomerById = async (customerId: string) => {
    try {
      const response = await searchBarClient.sendRequest({
        url: `/owner/customer/getbyid`,
        method: "post",
        body: {
          customerId: customerId,
        },
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
      if (response) {
        console.log("fetching by ID");
        console.log(response);
        setSearchBarResults(response);
        console.log(searchBarResults);
      }
    } catch (e) {
      console.log(e);
      console.log(searchBarClient.error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const getAggregatedCustomerLocation = async (customerLocationId: String) => {
    try {
      if (customerLocationId) {
        console.log(
          `Searching for customer location with id: ${customerLocationId}`
        );

        const aggregatedResponse = await getCustomerLocationClient.sendRequest({
          url: "/owner/customerlocation/getaggregatedbyid",
          method: "post",
          body: {
            locationId: customerLocationId,
          },
          headers: {
            headers: { "Content-Type": "application/json" },
            // withCredentials: true
          },
        });

        console.log("customer location aggregated");
        console.log(aggregatedResponse);

        if (aggregatedResponse) {
          setCustomerLocation(aggregatedResponse[0].customerLocationDetails);
          setProvince(aggregatedResponse[0].provinceDetails);
          setCity(aggregatedResponse[0].cityDetails);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomers = async () => {
    try {
      const response = await getCustomersClient.sendRequest({
        url: "/owner/customer",
        method: "get",
        body: null,
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log("response:");
      console.log(JSON.stringify(response[0]));
      if (response) {
        setCustomers(response);
        console.log("Customers:");
        console.log(customers);
      }
    } catch (e) {
      console.log(e);
      console.log(getCustomersClient.error);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 250,
      editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 250,
      editable: true,
    },
    {
      field: "deliveryAddress",
      headerName: "Delivery Address",
      width: 200,
      editable: true,
    },
    {
      field: "purchaseHistory",
      headerName: "Purchase History",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setCustomerPurchaseHistoryId(params.row.id);
            setDisplayOrdersByCustomer(true);
            console.log(
              `selected customer purchase history id: ` + params.row.id
            );
          }}
        />
      ),
    },
    {
      field: "location",
      headerName: "Location",
      width: 150,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={async () => {
            setCustomerLocation(undefined);
            setProvince(undefined);
            setCity(undefined);
            await getAggregatedCustomerLocation(params.row.deliveryAddress);
            setOpenLocationModal(true);
          }}
        />
      ),
    },
    {
      field: "numberOfOrders",
      headerName: "Number of Orders",
      width: 150,
    },
    {
      field: "numberOfOrderTransactions",
      headerName: "Number of Order Transactions",
      width: 200,
    },
  ];

  return displaySearchBarResults ? (
    // show search bar results

    <SearchBarList
      columns={columns}
      rows={searchBarResults}
      toggleSearchBarList={() =>
        setDisplaySearchBarResults(!displaySearchBarResults)
      }
      isLoading={searchBarClient.isLoading}
    />

  ) : displayOrdersByCustomer ? (
    // show the orders page filtered by customer (purchase history)

    <OrderList
      canChangeStatus={false}
      customerId={customerPurchaseHistoryId}
      returnToOriginalComponent={() => {
        setDisplayOrdersByCustomer(false);
      }}
    />
  ) : (
    // otherwise, show the main Customers page

    <div className="page">
      <SnackbarErrorClient client={getCustomersClient} />
      <div className="pageUpper">
        <p className="pageTitle">Customers</p>
      </div>

      <SearchBar
        fetchDataById={getCustomerById}
        toggleSearchBarList={() =>
          setDisplaySearchBarResults(!displaySearchBarResults)
        }
        customPlaceholderText="Enter Customer ID..."
      />

      <CustomerLocationPopup
        customerLocation={customerLocation}
        province={province}
        city={city}
        open={openLocationModal}
        setOpen={setOpenLocationModal}
      />

      <TableList
        rows={customers}
        isLoading={getCustomersClient.isLoading}
        columns={columns}
        showRowNumbersNonPaginated
        rowNumbersDescending
      />
    </div>
  );
};

export default CustomerList;
