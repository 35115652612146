import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { FaArrowLeft } from "react-icons/fa";
import TableList from "../../Components/Table/Table";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";

interface SearchBarListProps {
  columns: GridColDef[];
  rows: any[];
  toggleSearchBarList: () => void;
  isLoading: boolean;
}

const SearchBarList: React.FC<SearchBarListProps> = ({
  columns,
  rows,
  isLoading,
  toggleSearchBarList,
}) => {
  return (
    <div className="page">
      <div className="pageUpper">
        <p className="pageTitle">Search Results</p>
      </div>
      <FaArrowLeft onClick={toggleSearchBarList} />
      <TableList rows={rows} columns={columns} isLoading={isLoading} />
    </div>
  );
};

export default SearchBarList;
