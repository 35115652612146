import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

import AddUpdateCategory from "../../Components/AddUpdateCategory/AddUpdateCategory";
import { ICategory } from "../../Models/ICategory";
import TableList from "../../Components/Table/Table";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";

interface CategoryListProps {}

const CategoryList: React.FunctionComponent<CategoryListProps> = () => {
  const [categories, setCategories] = useState([[]]);
  const [parent, setParent] = useState<ICategory[]>([]);
  const [selectedParent, setSelectedParent] = useState<ICategory | null>();
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const getCategoriesClient = useHttpClient();
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const dataGridApiRef = useGridApiRef();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const deleteCategoryClient = useHttpClient();
  const [selectedCategoryToDelete, setSelectedCategoryToDelete] = useState<ICategory | null>();
  

  useEffect(() => {
    getCategories(1, null);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "logo",
      headerName: "Logo",
      width: 90,
      renderCell: (params) =>
        params.row.logo && (
          <img
            width={120}
            height={90}
            src={params.row.logo}
            alt="logo"
            onClick={() => {
              setOpenImageModal(true);
              setImageModalSrc(params.row.logo);
            }}
          />
        ),
    },
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "nameAr",
      headerName: "NameAr",
      width: 200,
      editable: true,
    },
    {
      field: "level",
      headerName: "Level",
      width: 100,
      editable: true,
    },
    {
      field: "size",
      headerName: "Size",
      width: 100,
      editable: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
    },
    {
      field: "forKids",
      headerName: "For Kids",
      width: 140,
    },

    {
      field: "create",
      headerName: "Create Category ",
      width: 120,
      renderCell: (params) => (
        <FaPlus
          onClick={() => {
            setOpenCategoryModal(true);
            let category = params.row;

            console.log("PP ARRAYY  ", category);

            if (!category.gender && selectedParent) {
              category.gender = selectedParent?.gender;
            }
            if (
              category.forKids !== true &&
              category.forKids !== false &&
              selectedParent
            ) {
              category.forKids = selectedParent?.forKids;
            }
            if (!category.size && selectedParent) {
              category.size = selectedParent?.size;
            }

            setSelectedParent(category);
            console.log("PP ARRAYY  ", category);
          }}
        />
      ),
    },
    {
      field: "children",
      headerName: "View Children",
      width: 120,
      renderCell: (params) =>
        params.row &&
        params.row.children &&
        params.row.children.length > 0 && (
          <FaEye
            onClick={() => {
              let category = params.row;

              let parentsArrays = [...parent, category];

              setParent(parentsArrays);

              if (!category.gender && selectedParent) {
                category.gender = selectedParent?.gender;
              }
              if (
                category.forKids !== true &&
                category.forKids !== false &&
                selectedParent
              ) {
                category.forKids = selectedParent?.forKids;
              }
              if (!category.size && selectedParent) {
                category.size = selectedParent?.size;
              }
              setSelectedParent(category);

              getCategories(null, category.id);

              // setStatus(i);
            }}
          />
        ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 120,
      renderCell: (params) => (
        <FaEdit
          onClick={() => {
            setSelectedCategory(params.row);
            setOpenCategoryModal(true);
          }}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            setSelectedCategoryToDelete(params.row);
            setOpenDeletePopup(true);
          }}
        />
      ),
    },
  ];

  if (selectedParent == null) {
    columns.push(
      {
        field: "sellRateAmount",
        headerName: "Sell Rate Amount",
        width: 140,
        valueGetter: (params) => params.row.sellRate?.sellRateAmount,
      },

      {
        field: "sellRatePercentage",
        headerName: "Sell Rate Percentage",
        width: 100,
        valueGetter: (params) => params.row.sellRate?.sellRatePercentage,
      }
    );
  }

  const getCategories = async (level: number | null, parent: any) => {
    try {
      const response = await getCategoriesClient.sendRequest({
        url: "/owner/category/get",
        method: "post",
        body: {
          level: level,
          parent: parent,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      if (response) {
        console.log(response);
        setCategories([...categories, response]);
      }
    } catch (e) {
      console.log(getCategoriesClient.error);
    }
  };

  const deleteCategory = async () => {
    try {
      const id = selectedCategoryToDelete?.id;

      const response = await deleteCategoryClient.sendRequest({
        url: "/owner/category/delete",
        method: "post",
        body: {
          id: id,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });

      if (response) {
        console.log(response);
        setCategories([]);
        await getCategories(1, null);
      }
    } catch (e) {
      console.log(deleteCategoryClient.error);
    }
  };
  

  const handleEvent = () => {
    setParent(parent.slice(0, -1));

    setCategories(categories.slice(0, -1));

    // upon returning to the general category list, go back to the first page
    dataGridApiRef.current.setPage(0);
  };

  const isFashion = (): boolean => {
    if (
      (parent.length > 0 &&
        (parent[0].name?.toLowerCase() === "fashion" ||
          parent[0].name?.toLowerCase() === "kids")) ||
      (selectedParent && selectedParent.name?.toLowerCase() === "fashion")
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (openCategoryModal === false) {
      setSelectedCategory(null);
    }
  }, [setOpenCategoryModal, openCategoryModal]);

  return (
    <div className="page">
      <SnackbarErrorClient client={getCategoriesClient} />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteCategory}
        deleteHttpClient={deleteCategoryClient}
      />
      
      <AddUpdateCategory
        parentId={selectedParent ? selectedParent.id : null}
        category={selectedCategory}
        isFashion={isFashion()}
        parentName={selectedParent ? selectedParent.name : null}
        parentGender={selectedParent ? selectedParent.gender : null}
        parentForKids={selectedParent ? selectedParent.forKids : null}
        parentSize={selectedParent ? selectedParent.size : null}
        getCategories={() => {
          if (parent.length === 0) {
            setCategories(categories.slice(-1));
            getCategories(
              parent.length === 0 ? 1 : null,
              parent.length === 0 ? null : parent[parent.length - 1]
            );
          } else {
            setCategories(categories.slice(-1));
            getCategories(null, parent[parent.length - 1]);
          }
        }}
        open={openCategoryModal}
        setOpen={setOpenCategoryModal}
      />

      <div className="pageUpper">
        {parent.length > 0 ? (
          <div
            style={{
              display: "flex",
              gap: "16px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaArrowLeft onClick={handleEvent} />
            <p style={{ marginBottom: 0 }} className="pageTitle">
              {parent[parent.length - 1]?.name} Categories
            </p>
          </div>
        ) : (
          <p className="pageTitle">Categories</p>
        )}
        {parent.length === 0 && (
          <div className="pageUpperCTAs">
            <CustomButton
              text="Add Category"
              onClick={() => {
                setSelectedParent(null);
                setSelectedCategory(null);
                setOpenCategoryModal(true);
              }}
              isSecondary={true}
            />
          </div>
        )}
      </div>

      <TableList
        rows={categories.length > 0 ? categories[categories.length - 1] : []}
        isLoading={getCategoriesClient.isLoading}
        columns={columns}
        dataGridApiRef={dataGridApiRef}
        showRowNumbersNonPaginated
      />
    </div>
  );
};

export default CategoryList;
