import React from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

interface AutofillStatusSelectorProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AutofillStatusSelector: React.FC<AutofillStatusSelectorProps> = ({
  value,
  onChange,
}) => {
  return (
    <RadioGroup
      row
      aria-label="autofill-status"
      name="autofill-status"
      value={value}
      onChange={onChange}
    >
      <FormControlLabel
        value="true"
        control={<Radio color="primary" />}
        label="True"
        labelPlacement="end"
      />
      <FormControlLabel
        value="false"
        control={<Radio color="primary" />}
        label="False"
        labelPlacement="end"
      />
    </RadioGroup>
  );
};

export default AutofillStatusSelector;
