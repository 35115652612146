import { useState, useCallback, useRef, useEffect } from "react";
import axios from "../Api/axios";
import useAxiosPrivate from "./useAxiosPrivate";

export const useCustomHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const axiosPrivate = useAxiosPrivate();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async ({
      url,
      method = "GET",
      body,
      headers = {},
      isPrivate = true,
      withCredentials = false,
      responseType = "json"
    }) => {
      console.log(url);
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      let ax;

      if (isPrivate === false) {
        ax = axios;
      } else {
        ax = axiosPrivate;
      }

      try {
        let response;

        response = await ax[method](
          url,
          body,
          { withCredentials: withCredentials },
          { headers: headers },
          { responseType: responseType },
          { signal: httpAbortCtrl.signal }
        );

        const responseData = response?.data;

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );

        setIsLoading(false);
        return responseData;
      } catch (err) {
        console.log(err);
        if (!err?.response) {
          setError("No server response");
        }

        try {
          setError(err?.response?.data.message);
        } catch (e) {
          setError("Problem occurred, please try again");
        }

        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError("");
  };

  const setLoading = (loading) => {
    setIsLoading(loading);
  };

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError, setLoading };
};
