import React, { useState, useRef, useEffect } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../Shared/useDebounceEffect";
import { canvasPreview } from "../../Shared/canvasPreview";
import { Console } from "console";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface ImageUploadProps {
  setImg: any;
  title: string;
  img?: any | null;
  aspectRatio?: number;
}

const ImageUpload: React.FunctionComponent<ImageUploadProps> = (props) => {
  const [imgSrc, setImgSrc] = useState("");
  const [propsImg, setPropsImg] = useState<any>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(1);

  useEffect(() => {
    console.log("2222");

    if (props.img) {
      console.log("1111");
      setPropsImg(props.img);
      // setImgSrc(props.img);
    } else {
      setPropsImg(null);
    }

    // set aspect ratio if passed by props, otherwise leave as 1
    if (props.aspectRatio) {
      setAspect(props.aspectRatio);
    }

  }, [props.img]);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      setPropsImg(null);
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      hiddenAnchorRef.current!.href = blobUrlRef.current;
      hiddenAnchorRef.current!.click();
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        console.log(previewCanvasRef);

        // if (propsImg) {
        //   if (previewCanvasRef.current) {
        //     console.log("we hereeee", propsImg);
        //     const canvas = previewCanvasRef.current;
        //     const ctx = canvas.getContext("2d");
        //     const img = new Image();
        //     console.log("we hereeee", 9999);

        //     // Specify the crossOrigin attribute to handle cross-origin images.
        //     img.crossOrigin = "Anonymous"; // or 'use-credentials' if required
        //     console.log("we hereeee", 121212121212);
        //     img.src = propsImg; // Replace with your image URL

        //     img.onload = () => {
        //       console.log("we hereeee", 222222);

        //       ctx?.drawImage(img, 0, 0);

        //       // Now you can safely use toBlob() without tainting the canvas.
        //       canvas.toBlob((blob) => {
        //         console.log("we hereeee", 3333333);

        //         if (blob) {
        //           if (blobUrlRef.current) {
        //             URL.revokeObjectURL(blobUrlRef.current);
        //           }
        //           console.log("we hereeee", 44444);

        //           blobUrlRef.current = URL.createObjectURL(blob);
        //           console.log(blob);
        //           props.setImg(blob);
        //         }
        //       });
        //     };

        //     // Set the image source
        //   }
        // } else {
        //   if (previewCanvasRef.current) {
        //     previewCanvasRef?.current?.toBlob((blob) => {
        //       if (blob) {
        //         if (blobUrlRef.current) {
        //           URL.revokeObjectURL(blobUrlRef.current);
        //         }
        //         blobUrlRef.current = URL.createObjectURL(blob);
        //         // hiddenAnchorRef.current!.href = blobUrlRef.current;
        //         // hiddenAnchorRef.current!.click();
        //         console.log(blob);
        //         props.setImg(blob);
        //         console.log(blobUrlRef);
        //         console.log(blobUrlRef.current);
        //       }
        //     });
        //   }
        // }

        if (previewCanvasRef.current) {
          previewCanvasRef?.current?.toBlob((blob) => {
            if (blob) {
              if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current);
              }
              blobUrlRef.current = URL.createObjectURL(blob);
              // hiddenAnchorRef.current!.href = blobUrlRef.current;
              // hiddenAnchorRef.current!.click();
              console.log(blob);
              props.setImg(blob);
              console.log(blobUrlRef);
              console.log(blobUrlRef.current);
            }
          });
        }
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     setAspect(undefined);
  //   } else {
  //     setAspect(16 / 9);

  //     if (imgRef.current) {
  //       const { width, height } = imgRef.current;
  //       const newCrop = centerAspectCrop(width, height, 16 / 9);
  //       setCrop(newCrop);
  //       // Updates the preview
  //       setCompletedCrop(convertToPixelCrop(newCrop, width, height));
  //     }
  //   }
  // }

  return (
    <div className="App">
      <div className="Crop-Controls">
        <p>{props.title}</p>
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      {!!imgSrc && !propsImg && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          // minWidth={400}
          // minHeight={300}
        >
          <img
            // crossOrigin="anonymous"

            // width={300}
            height={300}
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{
              transform: `scale(${scale}) rotate(${rotate}deg)`,
              // height: 350,
            }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {!!completedCrop && !propsImg && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
          <div></div>
        </>
      )}
      { propsImg &&
         <img
         // crossOrigin="anonymous"

         // width={300}
         height={300}
         alt="Crop me"
         src={propsImg}
         style={{
           transform: `scale(${scale}) rotate(${rotate}deg)`,
         }}
         onLoad={onImageLoad}
       />
      }
    </div>
  );
};

export default ImageUpload;
