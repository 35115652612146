const handleRemoveItem = (index: number, itemList: any[], setItemList: React.Dispatch<React.SetStateAction<any[]>>) => {
    const updatedList = itemList.filter((_, idx) => idx !== index);
    setItemList(updatedList);
};

const handleAddItem = (
    newItem: string,
    setNewItem: React.Dispatch<React.SetStateAction<string>>,
    itemList: string[],
    setItemList: React.Dispatch<React.SetStateAction<string[]>>
) => {
    if (newItem.trim() !== "") {
        setItemList([...itemList, newItem.trim()]);
        setNewItem("");
    }
};

export { handleRemoveItem,  handleAddItem };