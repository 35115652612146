import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import {
  Alert,
  Box,
  Button,
  Card,
  CardMedia,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";
import { ICategory } from "../../Models/ICategory";
import { Cropper, getCroppedImg } from "react-cropper-custom";
import ImageUpload from "../ImageUpload/ImageUpload";
import imageCompression from "browser-image-compression";
import { IVendor } from "../../Models/IVendor";
import { IHomeSection } from "../../Models/IHomeSection";
import { IDiscount } from "../../Models/IDiscount";
import { HomeSectionType } from "../../Data/Enums";
import "./AddUpdateHomeSection.scss";
import AutofillStatusSelector from "./AutofillStatusSelector";
import SectionTypeSelector from "./SectionTypeSelector";
import CustomFormInputList from "../CustomFormInputList/CustomFormInputList";
import IconUploadSelector from "../IconUploadSelector.tsx/IconUploadSelector";
import SubtypeSelector from "./SubTypeSelector";
import { handleAddItem, handleRemoveItem } from "../CustomFormInputList/CustomFormInputListUtils";

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

interface AddUpdateHomeSectionProps {
  homeSection?: IHomeSection | null;

  open: any;
  setOpen: any;
  getHomeSections: any;
}

const AddUpdateHomeSection: React.FunctionComponent<
  AddUpdateHomeSectionProps
> = (props) => {
  const [error, setError] = useState("");
  const handleClose = () => props.setOpen(false);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [initImageSrc, setInitImageSrc] = useState<any>(null);

  // const [img, setImg] = useState("./2521.jpg");
  const [zoom, setZoom] = useState(1);
  const [img, setImg] = useState("./2521.jpg");

  const submitHomeSectionClient = useHttpClient();

  const [vendors, setVendors] = useState<string[]>([]);
  const [newVendor, setNewVendor] = useState<string>("");

  const [categories, setCategories] = useState<string[]>([]);
  const [newCategory, setNewCategory] = useState("");

  const [products, setProducts] = useState<string[]>([]);
  const [newProduct, setNewProduct] = useState<string>("");

  const [discounts, setDiscounts] = useState<string[]>([]);
  const [newDiscount, setNewDiscount] = useState<string>("");

  const [uploadNewImage, toggleUploadNewImage] = useState<boolean>(true);
  const [homeSectionType, setHomeSectionType] = useState("products");
  const [autoFillStatus, setAutoFillStatus] = useState<"true" | "false">(
    "true"
  );
  // const [name, setName] = useState<string>("");
  // const [nameAr, setNameAr] = useState<string>("");
  const [subType, setSubType] = useState<string>("");

  let defValues = {};

  useEffect(() => {
    setError("");
    setVendors([]);
    setCategories([]);
    setProducts([]);
    setDiscounts([]);

    if (props.homeSection) {
      // setName(props.homeSection.name || "N/A");
      // setNameAr(props.homeSection.nameAr || "N/A");
      setVendors(props.homeSection.vendors || []);
      setCategories(props.homeSection.categories || []);
      setProducts(props.homeSection.products || []);
      setDiscounts(props.homeSection.discounts || []);
      setHomeSectionType(props.homeSection.type || "N/A");
      setAutoFillStatus(props.homeSection.autoFill ? "true" : "false");
      setSubType(props.homeSection.subType || "N/A");
    }
    console.log("props:");
    console.log(props);
    console.log("vendors, categories, products, discounts: ");
    console.log(vendors);
    console.log(categories);
    console.log(products);
    console.log(discounts);

    // if (props.product) {
    //   console.log(props.product);
    defValues = {};
    setImage({ preview: "", data: "" });

    setImageSrc(null);

    reset(defValues);
    // } else {
    // }
    console.log("hoooooooo");
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const handleSectionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHomeSectionType(event.target.value as HomeSectionType);
    console.log("homesectiontype: ");
    console.log(homeSectionType);
  };

  const handleSubTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubType(event.target.value);
    console.log("subtype: ");
    console.log(subType);
  };

  const handleAutoFillStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAutoFillStatus(event.target.value as "true" | "false");
  };

  const handleIconUploadChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedValue = event.target.value === "true" ? true : false;
    toggleUploadNewImage(updatedValue);
  };

  const onSubmit = async () => {
    const values = control._formValues;

    try {
      let formData = new FormData();

      submitHomeSectionClient.setLoading(true);

      if (imageSrc != null) {
        console.log("IMMMM SOURCE");
        const compressedFile = await imageCompression(imageSrc, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        });

        console.log("IMMMM SOURCE");

        formData.append("image", compressedFile);
      }
      console.log("Image: ");
      console.log(image);

      submitHomeSectionClient.setLoading(false);

      vendors.forEach((vendor) => {
        vendor = vendor.trim();
      });

      formData.append("name", values.name);
      formData.append("nameAr", values.nameAr);
      formData.append("subType", subType);
      formData.append("type", homeSectionType);
      formData.append("autoFill", String(autoFillStatus));
      formData.append("vendors", JSON.stringify(vendors));
      formData.append("products", JSON.stringify(products));
      formData.append("discounts", JSON.stringify(discounts));
      formData.append("categories", JSON.stringify(categories));

      // Check formData
      console.log("checking formdata");
      const formDataObject: any = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      console.log(formDataObject);

      // If editing an existing section
      if (props.homeSection) {
        formData.append("id", props.homeSection.id ?? "");
        formData.append("icon", props.homeSection.icon ?? "");

        console.log("FORMDATAAAA", formData.get("images"));

        const response = await submitHomeSectionClient.sendRequest({
          url: "/owner/homesection",
          // url: "[link]",
          method: "put",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });

        // If Creating a new section
      } else {
        const response = await submitHomeSectionClient.sendRequest({
          url: "/owner/homesection",
          method: "post",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
      }

      props.setOpen(false);
      props.getHomeSections();
    } catch (e) {
      console.log(e);
      submitHomeSectionClient.setLoading(false);
    }
    console.log(values);
  };

  return (
    <div>
      <SnackbarErrorClient client={submitHomeSectionClient} />
      <PageLoader loading={submitHomeSectionClient.isLoading} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.homeSection ? "Edit" : "Create"}{" "}
            {props.homeSection?.name ?? ""} Home Section
          </p>
          <h3>Section Type</h3>

          {/* Choose section type */}

          <SectionTypeSelector
            value={homeSectionType}
            onChange={handleSectionTypeChange}
          />

          {/* Choose autofill status */}

          <h3>Autofill Status</h3>

          <AutofillStatusSelector
            value={autoFillStatus}
            onChange={handleAutoFillStatusChange}
          />

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <FormInputText
              rules={{
                required: true,
              }}
              value={props.homeSection?.name}
              control={control}
              name="name"
              label={"name"}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              value={props.homeSection?.nameAr}
              name="nameAr"
              label={"Name Arabic"}
            />

            {/* Subtype selector */}

            <SubtypeSelector value={subType} onChange={setSubType} />

            {/* Add vendors to home section */}

            {((autoFillStatus === "false" && homeSectionType === "vendors") ||
              (autoFillStatus === "true" && homeSectionType !== "vendors")) && (
              <CustomFormInputList
                title="Vendors"
                items={vendors}
                newItem={newVendor}
                onNewItemChange={setNewVendor}
                onAddItem={()=>{ handleAddItem(newVendor, setNewVendor, vendors, setVendors) }}
                onRemoveItem={(index) => handleRemoveItem(index, vendors, setVendors)} 
              />
            )}

            {/* Add categories to home section */}

            {autoFillStatus === "true" && (
              <CustomFormInputList
                title="Categories"
                items={categories}
                newItem={newCategory}
                onNewItemChange={setNewCategory}
                onAddItem={()=>{handleAddItem(newCategory, setNewCategory, categories, setCategories)}}
                onRemoveItem={(index) => handleRemoveItem(index, categories, setCategories)} 
              />
            )}

            {/* Add products to home section */}
            {((autoFillStatus === "false" && homeSectionType === "products") ||
              (autoFillStatus === "true" &&
                homeSectionType !== "products")) && (
              <CustomFormInputList
                title="Products"
                items={products}
                newItem={newProduct}
                onNewItemChange={setNewProduct}
                onAddItem={()=>{handleAddItem(newProduct, setNewProduct, products, setProducts)}}
                onRemoveItem={(index) => handleRemoveItem(index, products, setProducts)} 
              />
            )}

            {/* Add discounts to home section */}
            {autoFillStatus === "true" && (
              <CustomFormInputList
                title="Discounts"
                items={discounts}
                newItem={newDiscount}
                onNewItemChange={setNewDiscount}
                onAddItem={()=>{handleAddItem(newDiscount, setNewDiscount, discounts, setDiscounts)}}
                onRemoveItem={(index) => handleRemoveItem(index, discounts, setDiscounts)} 
              />
            )}

            {/* Choose whether to upload new image or use existing image */}

            <IconUploadSelector
              uploadNewImage={uploadNewImage}
              handleIconUploadChange={handleIconUploadChange}
            />

            {/* Show existing image */}

            {/* Show message if image url exists */}
            {!uploadNewImage &&
              (props.homeSection?.icon ? (
                <p>Existing image found.</p>
              ) : (
                <p>No existing image found</p>
              ))}

            {!uploadNewImage && (
              <img width={90} height={90} src={props.homeSection?.icon} />
            )}

            {/* Upload new image */}
            {uploadNewImage && (
              <ImageUpload
                title={"Main Image"}
                img={initImageSrc}
                setImg={setImageSrc}
                aspectRatio={16/9}
              />
            )}

            {error && <Alert severity="error">{error}</Alert>}

            {/* Submit */}
            <CustomButton
              text={props.homeSection ? "Edit" : "Create"}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdateHomeSection;
