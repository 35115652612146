import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";

import TableList from "../../Components/Table/Table";
import { Button, MenuItem, Select } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import CustomerList from "../CustomerList/CustomerList";
import OrderTransactionList from "../OrderTransactionList/OrderTransactionList";
import { AdminStatusEnum, OrderListType } from "../../Data/Enums";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import formatDate from "../../Utils/formatDate";
import SearchBarList from "../../Components/SearchBarList/SearchBarList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { pageSize } from "../../Shared/globalVar";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useCustomHttpClient } from "../../Hooks/useCustomHttpClient";
import * as XLSX from 'xlsx';

interface OrderListProps {
  canChangeStatus: boolean;
  vendorTransactionId?: string | null;
  transactionId?: string | null;
  customerId?: string | null;
  returnToOriginalComponent?: () => void;
}

const OrderList: React.FunctionComponent<OrderListProps> = (
  props: OrderListProps
) => {
  const [orders, setOrders] = useState([]);
  const getOrdersClient = useHttpClient();
  const updateAdminStatusClient = useHttpClient();
  const [count, setCount] = useState(0);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");

  const searchBarClient = useHttpClient();
  const [searchBarResults, setSearchBarResults] = useState([]);
  const [displaySearchBarResults, setDisplaySearchBarResults] = useState(false);
  const [status, setStatus] = useState(0);
  const dataGridApiRef = useGridApiRef();
  const exportToExcelClient = useCustomHttpClient();

  let orderAdminStatuses = [
    {
      name: "All",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    { 
      name: AdminStatusEnum.Pending, 
      orders: [], 
      needsLoading: true, 
      count: 0 
    },
    { 
      name: AdminStatusEnum.Received,
      // name: "Received",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Packed,
      // name: "Packed",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Shipped,
      // name: "Shipped",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    { 
      name: AdminStatusEnum.Returned, 
      orders: [], 
      needsLoading: true, 
      count: 0 
    },
  ];

  let columns: GridColDef[] = [
    {
      field: "productImage",
      headerName: "Product Image",
      width: 150,
      renderCell: (params) =>
        params.row.productImage && (
          <img
            width={100}
            height={120}
            src={params.row.productImage}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.productImage);
            }}
          />
        ),
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 230,
      editable: true,
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      width: 230,
      editable: true,
    },
    {
      field: "_id",
      headerName: "Order Item ID",
      width: 230,
      editable: true,
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 120,
      editable: true,
    },
    {
      field: "categoryName",
      headerName: "Category",
      width: 100,
      editable: true,
    },
    {
      field: "topLevelCategoryName",
      headerName: "Top Level Category",
      width: 130,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 70,
      editable: false,
    },
    {
      field: "size",
      headerName: "Size",
      width: 20,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 70,
      editable: true,
    },
    {
      field: "vendor",
      headerName: "Vendor ID",
      width: 225,
      editable: true,
    },
    {
      field: "airwayBill",
      headerName: "Airway Bill",
      width: 120,
      editable: true,
    },
    {
      field: "color",
      headerName: "Color",
      width: 100,
      editable: true,
    },
    {
      field: "delivered",
      headerName: "Delivered",
      width: 80,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 170,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
  ];

  columns = props.canChangeStatus
    ? [
        ...columns,
        {
          field: "adminStatus",
          headerName: "Admin Status",
          width: 150,
          editable: true,
          renderCell: (params) => (
            <Select
              value={params.row.adminStatus}
              onChange={async (e) => {
                console.log(params);
                await updateAdminStatus(
                  params.row._id,
                  params.row.orderId,
                  e.target.value
                );
                await getOrders(true);
              }}
              style={{ backgroundColor: 
                params.row.adminStatus === AdminStatusEnum.Shipped ? 'rgba(77, 74, 191, 0.5)' : 
                params.row.adminStatus === AdminStatusEnum.Packed ? 'rgba(253, 177, 102, 0.5)' : 
                params.row.adminStatus === AdminStatusEnum.Received ? 'rgba(204, 255, 229, 0.5)' :
                params.row.adminStatus === AdminStatusEnum.Returned ? 'rgba(255, 200, 195, 0.5)' :
                ''
              }}
            >
              {orderAdminStatuses
                .filter((status) => status.name !== "All")
                .map((status) => (
                  <MenuItem value={status.name}>{status.name}</MenuItem>
                ))}
            </Select>
          ),
        },
      ]
    : columns;

  const getOrders = async (getCount: boolean, adminStatusUpdated?: boolean) => {
    const currentPage =
      dataGridApiRef.current.state.pagination.paginationModel.page;

    const skipAmount = adminStatusUpdated ? currentPage * 10 : orders.length;

    try {
      let url = `/owner/orders?skip=${skipAmount}&descending=true`;
      // let customerId = '';
      // let orderTransactionId = '';

      if (props.customerId) {
        url = `/owner/orders/getbycustomer?skip=${skipAmount}&customerId=${props.customerId}`;
        // customerId= props.customerId;
      } else if (props.transactionId) {
        url = `/owner/orders/getbytransaction?skip=${skipAmount}&orderTransactionId=${props.transactionId}`;
        // orderTransactionId = props.transactionId;
      } else if (props.vendorTransactionId) {
        url = `/owner/orders?skip=${skipAmount}&vendorTransactionId=${props.vendorTransactionId}`;
        // orderTransactionId = props.transactionId;
      }

      const response = await getOrdersClient.sendRequest({
        url: url,
        method: "post",
        body: {
          // customerId: customerId,
          // orderTransactionId: orderTransactionId,
          getCount: getCount,
          status: status === 0 ? null : orderAdminStatuses[status].name,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
        },
      });

      if (response && response.data) {
        console.log(response);

        // setOrders([...orders, ...(response.data as [])]);

        let allOrders;

        // ensure that current page is re-fectched if admin status of a row is updated
        if (adminStatusUpdated) {
          allOrders = [...(response.data as [])];
        } else {
          allOrders = [...orders, ...(response.data as [])];
        }

        setOrders(allOrders);

        console.log(orders);

        if (getCount) {
          setCount(response.count);
          console.log("Response count: ", response.count);
        }
      }
    } catch (e) {
      console.log(getOrdersClient.error);
    }
  };

  const exportToExcel = async () => {
    try {
      const response = await exportToExcelClient.sendRequest({
        url: "/owner/orders/export/excel",
        method: "post",
        body: null,
        headers: {
          headers: {
            // "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      if (response) {
        createExcelFile(response, "Orders", "Orders.xlsx");
      } else {
        console.error(
          "no valid response received from the server for export to excel"
        );
      }
    } catch (e) {
      console.log(e);
      console.log(searchBarClient.error);
    }
  };

  function createExcelFile(data: any[], sheetName: string, fileName: string): void {

    const worksheet = XLSX.utils.json_to_sheet(data);
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    
    const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    
    function binaryStringToArrayBuffer(binaryString: string): ArrayBuffer {
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i) & 0xFF;
      }
      return arrayBuffer;
    }
    
    const arrayBuffer = binaryStringToArrayBuffer(excelBinaryString);
    const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadUrl = window.URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');
    anchorElement.href = downloadUrl;
    // anchorElement.download = `cars.xlsx`;
    anchorElement.download = fileName;
    anchorElement.click();
    
    window.URL.revokeObjectURL(downloadUrl);
  }
  

  const exportToCSV = async () => {
    try {
      const response = await exportToExcelClient.sendRequest({
        url: "/owner/orders/export/csv?descending=true",
        method: "post",
        body: null,
        responseType: "blob",
        headers: {
          headers: {
            // "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      if (response) {
        const blob = new Blob([response], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "no valid response received from the server for export to csv"
        );
      }
    } catch (e) {
      console.log(e);
      console.log(searchBarClient.error);
    }
  };

  const getOrderById = async (orderId: string) => {
    try {
      const response = await searchBarClient.sendRequest({
        url: `/owner/orders/getbyid?orderId=${orderId}`,
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
      if (response) {
        console.log("fetching order by ID");
        console.log(response);
        setSearchBarResults(response.data);
        console.log(searchBarResults);
      }
    } catch (e) {
      console.log(e);
      console.log(searchBarClient.error);
    }
  };

  const updateAdminStatus = async (
    orderItemId: string,
    orderId: string,
    adminStatus: string
  ) => {
    try {
      const response = await updateAdminStatusClient.sendRequest({
        url: "/owner/orders/adminStatus",
        method: "put",
        body: {
          orderItemId,
          orderId,
          adminStatus,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
        },
      });

      if (response) {
        // remove last 10 orders to show admin status of updated order
        setOrders(orders.splice(-pageSize));
      }
      await getOrders(true);
    } catch (e) {
      console.log(getOrdersClient.error);
    }
  };

  useEffect(() => {
    getOrders(true);
  }, [props.canChangeStatus, status]);

  return displaySearchBarResults ? (
    // show search bar results

    <SearchBarList
      toggleSearchBarList={() =>
        setDisplaySearchBarResults(!displaySearchBarResults)
      }
      columns={columns}
      rows={searchBarResults}
      isLoading={searchBarClient.isLoading}
    />
  ) : (
    // otherwise, show the main Orders page

    <div className="page">
      <SnackbarErrorClient client={getOrdersClient} />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <div
        style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        
        {props.returnToOriginalComponent && (
          // if Orders has been rendered from other component (e.g. Customers), display arrow to return to original component
          <FaArrowLeft onClick={props.returnToOriginalComponent} />
        )}

        <p style={{ marginBottom: 0 }} className="pageTitle">
          {props.canChangeStatus ? "Inventory" : "Orders"}
        </p>
      </div>

      {props.canChangeStatus ? (
        // if displaying Inventory, show action buttons
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {orderAdminStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setCount(0);
                setOrders([]);
                setStatus(i);
                dataGridApiRef.current.setPage(0);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      ) : (
        // otherwise, do not display action buttons
        <p></p>
      )}

      <SearchBar
        fetchDataById={getOrderById}
        toggleSearchBarList={() =>
          setDisplaySearchBarResults(!displaySearchBarResults)
        }
      />

      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          style={{ width: "200px" }}
          onClick={async () => {
            await exportToCSV();
          }}
        >
          Export to CSV
        </Button>
      </div>

      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          style={{ width: "200px" }}
          onClick={async () => {
            await exportToExcel();
          }}
        >
          Export to Excel
        </Button>
      </div>

      <TableList
        // key={props.canChangeStatus.toString()}
        rows={orders}
        isLoading={
          getOrdersClient.isLoading || updateAdminStatusClient.isLoading
        }
        columns={columns}
        rowsCount={count}
        getItems={(p) => getOrders(false)}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />
    </div>
  );
};

export default OrderList;
