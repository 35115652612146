import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import TableList from "../../Components/Table/Table";
import AddUpdateTouristSection from "../../Components/AddUpdateTouristSection/AddUpdateTouristSection";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { ITouristSection } from "../../Models/ITouristSection";
import { FaEdit, FaTrash } from "react-icons/fa";
import { MenuItem, Select } from "@mui/material";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";
// import { ITouristSection } from "../../Models/ITouristSection";

interface TouristSectionListProps {}

const TouristSectionList: React.FunctionComponent<
  TouristSectionListProps
> = () => {
  const [TouristSections, setTouristSections] = useState<
    /*ITouristSection*/ []
  >([]);
  const [selectedTouristSection, setSelectedTouristSection] = useState<ITouristSection | null>();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const getTouristSectionsClient = useHttpClient();
  const [openAddUpdateModal, setOpenAddUpdateModal] = useState(false);
  const deleteTouristSectionClient = useHttpClient();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedTouristSectionToDelete, setSelectedTouristSectionToDelete] = useState<ITouristSection | null>(null);

  useEffect(() => {
    if (openAddUpdateModal === false) {
      setSelectedTouristSection(null);
    }
  }, [openAddUpdateModal, setOpenAddUpdateModal]);
  

  useEffect(() => {
    getTouristSections();
  }, []);


  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "Image",
      headerName: "Image",
      width: 90,
      renderCell: (params) =>
        params.row.image && (
          <img
            width={120}
            height={90}
            src={params.row.image}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.image);
            }}
          />
        ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "nameAr",
      headerName: "Name AR",
      width: 100,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category ID",
      width: 150,
      editable: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      renderCell: (params) => (
        <FaEdit
          onClick={() => {
            setSelectedTouristSection(params.row);

            setOpenAddUpdateModal(true);
          }}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.row.isActive}
          onChange={async (e) => {
            console.log(
              `Updating active status. New status: ${e.target.value}`
            );
            await updateActiveStatus(params.row.id, e.target.value === "true")
            await getTouristSections();
          }}
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            setSelectedTouristSectionToDelete(params.row);
            setOpenDeletePopup(true);
          }}
        />
      ),
    },
  ];

  const getTouristSections = async () => {
    try {
      const response = await getTouristSectionsClient.sendRequest({
        url: "/owner/homesection/touristic",
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
      if (response) {
        const responseWithIds = response.map((item: any) => {
          return { ...item, id: item._id };
        });
        console.log(responseWithIds);
        setTouristSections(responseWithIds);
        // setTouristSections(response);
      }
    } catch (e) {
      console.log(e);
      console.log(getTouristSectionsClient.error);
    }
  };

  const updateActiveStatus = async (id: any, isActive: any) => {

    try {
      const response = await getTouristSectionsClient.sendRequest({
        url: "/owner/homesection/isactive",
        method: "put",
        body: { id, isActive, sectionType: "touristic" },
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);

    } catch (e) {
      console.log(e);
      console.log(getTouristSectionsClient.error);
    }
  };

  const deleteTouristSection = async() => {
    try {
      const id = selectedTouristSectionToDelete?.id;

      const response = await deleteTouristSectionClient.sendRequest({
        url: "/owner/homesection/touristic/delete",
        method: "post",
        body: {
          id: id
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      if (response) {
        console.log(response);
      }
      setTouristSections([]);
      await getTouristSections();
    } catch (e) {
      console.log(deleteTouristSectionClient.error);
    }
  };


  return (
    <div className="page">
      <SnackbarErrorClient client={getTouristSectionsClient} />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteTouristSection}
        deleteHttpClient={deleteTouristSectionClient}
      />

      <AddUpdateTouristSection
        open={openAddUpdateModal}
        touristSection={selectedTouristSection}
        setOpen={setOpenAddUpdateModal}
        getTouristSections={getTouristSections}
      />

      <div className="pageUpper">
        <p className="pageTitle">Tourist Sections</p>
        <CustomButton
          text="Add Tourist Section"
          onClick={() => {
            setOpenAddUpdateModal(true);
          }}
          isSecondary={true}
        />
      </div>

      <TableList
        rows={TouristSections}
        isLoading={getTouristSectionsClient.isLoading}
        columns={columns}
      />
    </div>
  );
};

export default TouristSectionList;
