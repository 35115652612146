import axios from "axios";
import CryptoJS from "crypto-js";
const BASE_URL = process.env.REACT_APP_API_URL;

function encodeHmac(key: any, message: any) {
  const hmac = CryptoJS.HmacSHA256(message, key);
  const base64Hmac = CryptoJS.enc.Base64.stringify(hmac);
  return base64Hmac; 
}

  const timestamp = Math.floor(new Date().getTime()).toString();

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "timestamp": timestamp,
    "api-key": "APP KEYYY",
    "app-key": "APP KEYYY",
    jsnvaxa: encodeHmac(
      "PfrNAT4J1AYx1Yv8h3zk6SdF369LSFw20Flv6qOuumE9cojRZI4eGRpdfNPajz7oCwO54Vczuv63twIc",
      timestamp + ":726"
    ),
  },

  // withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "timestamp": timestamp,

    // "Content-Type": "application/json",
    jsnvaxa: encodeHmac(
      "PfrNAT4J1AYx1Yv8h3zk6SdF369LSFw20Flv6qOuumE9cojRZI4eGRpdfNPajz7oCwO54Vczuv63twIc",
      timestamp + ":726"
    ),
  },
  withCredentials: true,
});
