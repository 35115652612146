import { useContext, useDebugValue } from "react";
import AuthContext from "../Context/AuthProvider";
import { IAuth } from "../Models/IAuth";



const useAuth = () => {
  const auth = useContext(AuthContext) as IAuth;
  useDebugValue(auth, (auth) => (auth?.username ? "Logged In" : "Logged Out"));
  return auth;
};


export default useAuth;
