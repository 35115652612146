import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal } from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";
import { ICategory } from "../../Models/ICategory";
import { Cropper, getCroppedImg } from "react-cropper-custom";
import ImageUpload from "../ImageUpload/ImageUpload";
import imageCompression from "browser-image-compression";
import { IVendor } from "../../Models/IVendor";
import { IAdSection } from "../../Models/IAdSection";
import { IDiscount } from "../../Models/IDiscount";
import IconUploadSelector from "../IconUploadSelector.tsx/IconUploadSelector";

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

interface AddUpdateAdSectionProps {
  adSection?: IAdSection | null;

  open: any;
  setOpen: any;
  getAdSections: any;
}

const AddUpdateAdSection: React.FunctionComponent<AddUpdateAdSectionProps> = (
  props
) => {
  const [error, setError] = useState("");
  const handleClose = () => props.setOpen(false);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [initImageSrc, setInitImageSrc] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<"product" | "other">(
    "product"
  );
  const [uploadNewImage, toggleUploadNewImage] = useState<boolean>(true);

  // const [img, setImg] = useState("./2521.jpg");
  const [zoom, setZoom] = useState(1);
  const [img, setImg] = useState("./2521.jpg");

  const submitAdSectionClient = useHttpClient();

  let defValues = {};

  useEffect(() => {
    setError("");

    // if (props.product) {
    //   console.log(props.product);
    defValues = {};
    setImage({ preview: "", data: "" });

    setImageSrc(null);

    reset(defValues);
    // } else {
    // }
    console.log("hoooooooo");
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const handleIconUploadChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedValue = event.target.value === "true" ? true : false;
    toggleUploadNewImage(updatedValue);
  };

  const onSubmit = async () => {
    const values = control._formValues;

    try {
      let formData = new FormData();

      submitAdSectionClient.setLoading(true);

      if (imageSrc != null) {
        const compressedFile = await imageCompression(imageSrc, {
          maxSizeMB: 1.75,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        });

        formData.append("image", compressedFile);
      }
      console.log("Image: ");
      console.log(image);

      submitAdSectionClient.setLoading(false);

      formData.append("name", values.name);
      formData.append("nameAr", values.nameAr);

      if (selectedOption === "product") {
        formData.append("productId", values.productId);
      } else {
        formData.append("vendorId", values.vendorId);
        formData.append("discountId", values.discountId);
        formData.append("categoryId", values.categoryId);
      }

      // If editing an existing ad section
      if (props.adSection) {
        formData.append("id", props.adSection.id ?? "");

        console.log("FORMDATAAAA", formData.get("images"));

        const response = await submitAdSectionClient.sendRequest({
          url: "/owner/homesection/ad/item",
          method: "put",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });

        // If Creating a new ad section
      } else {
        const response = await submitAdSectionClient.sendRequest({
          url: "/owner/homesection/ad/item",
          method: "post",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
      }

      props.setOpen(false);
      props.getAdSections();
    } catch (e) {
      console.log(e);
      submitAdSectionClient.setLoading(false);
    }
    console.log(values);
  };

  const toggleOption = (option: "product" | "other") => {
    setSelectedOption(option);
    if (option === "product") {
      reset({ ...defValues, productId: "" });
    } else {
      reset({ ...defValues, vendorId: "", discountId: "", categoryId: "" });
    }
  };

  const handleFileChange = (e: any) => {
    if (e.target.files[0]) {
      const img = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      };
      setImage(img);
      setImg(img.data);
      console.log(img.data);
    }
  };

  const onCropComplete = async (croppedArea: Area) => {
    try {
      const imageS = await getCroppedImg(image.preview, croppedArea);
      console.log(imageS);

      setImg(imageS);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <SnackbarErrorClient client={submitAdSectionClient} />
      <PageLoader loading={submitAdSectionClient.isLoading} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.adSection ? "Edit" : "Create"} {props.adSection?.name ?? ""}{" "}
            Ad Section
          </p>

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <FormInputText
              rules={{
                required: true,
              }}
              value={props.adSection?.name}
              control={control}
              name="name"
              label={"name"}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              value={props.adSection?.nameAr}
              name="nameAr"
              label={"Name Arabic"}
            />
            {/* <input type="file" name="file" title="Main I" onChange={handleFileChange}></input> */}
            <div>
              <label>
                <input
                  type="radio"
                  value="product"
                  checked={selectedOption === "product"}
                  onChange={() => toggleOption("product")}
                />
                Product ID
              </label>
              <label>
                <input
                  type="radio"
                  value="other"
                  checked={selectedOption === "other"}
                  onChange={() => toggleOption("other")}
                />
                Vendor / Discount / Category ID
              </label>
            </div>
            {selectedOption === "product" ? (
              <FormInputText
                rules={{}}
                control={control}
                name="productId"
                value={props.adSection?.product}
                label={"Product ID"}
              />
            ) : (
              <>
                <FormInputText
                  rules={{}}
                  control={control}
                  name="vendorId"
                  value={props.adSection?.vendor}
                  label={"Vendor ID"}
                />
                <FormInputText
                  rules={{}}
                  control={control}
                  name="discountId"
                  value={props.adSection?.discount}
                  label={"Discount ID"}
                />
                <FormInputText
                  rules={{}}
                  control={control}
                  name="categoryId"
                  value={props.adSection?.category}
                  label={"Category ID"}
                />
              </>
            )}

            <IconUploadSelector
              uploadNewImage={uploadNewImage}
              handleIconUploadChange={handleIconUploadChange}
            />

            {/* Show existing image */}

            {/* Show message if image url exists */}
            {!uploadNewImage &&
              (props.adSection?.image ? (
                <p>Existing image found.</p>
              ) : (
                <p>No existing image found</p>
              ))}

            {!uploadNewImage && (
              <img width={90} height={90} src={props.adSection?.image} />
            )}

            {/* Upload new image */}
            {uploadNewImage && (
              <ImageUpload
                title={"Main Image"}
                img={initImageSrc}
                setImg={setImageSrc}
                aspectRatio={16 / 9}
              />
            )}

            {error && <Alert severity="error">{error}</Alert>}

            <CustomButton
              text={props.adSection ? "Edit" : "Create"}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdateAdSection;
