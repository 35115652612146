import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { ProductStatus } from "../../Data/Enums";
import ReviewProduct from "../../Components/ReviewProduct/ReviewProduct";
import TableList from "../../Components/Table/Table";
import formatDate from "../../Utils/formatDate";
import SearchBarList from "../../Components/SearchBarList/SearchBarList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa";
import VendorList from "../VendorList/VendorList";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import MultipleImagePopup from "../../Components/MultipleImagePopup/MultipleImagePopup";

interface ProductListProps {
  vendorId?: string | null;
  returnToOriginalComponent?: () => void;
}

const ProductList: React.FunctionComponent<ProductListProps> = (
  props: ProductListProps
) => {
  const [status, setStatus] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const getProductClient = useHttpClient();
  const [openReview, setOpenReview] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrcs, setImageModalSrcs] = useState<string[]>([]);

  const searchBarClient = useHttpClient();
  const [searchBarResults, setSearchBarResults] = useState<any[]>([]);
  const [displaySearchBarResults, setDisplaySearchBarResults] = useState(false);
  const dataGridApiRef = useGridApiRef();

  let productStatuses = [
    { name: ProductStatus.Pending, products: [], needsLoading: true, count: 0 },
    {
      name: ProductStatus.Accepted,
      products: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: ProductStatus.Rejected,
      products: [],
      needsLoading: true,
      count: 0,
    },
  ];

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "images",
      headerName: "Images",
      width: 90,
      renderCell: (params) =>
        params.row.images && (
          <img
            width={120}
            height={90}
            src={params.row.images[0]}
            alt="image"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrcs(params.row.images);
            }}
          />
        ),
    },
    {
      field: "review",
      headerName: "Review Product",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            handleEvent(params);
          }}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      editable: true,
    },
    {
      field: "discountedPrice",
      headerName: "Discounted Price",
      width: 100,
      editable: true,
    },
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 200,
      editable: true,
    },
    {
      field: "vendor",
      headerName: "Vendor Id",
      width: 250,
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 190,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
  ];

  const getProducts = async (
    getCount: boolean,
    adminStatusUpdated?: boolean
  ) => {
    setSelectedProduct(null);

    const currentPage =
      dataGridApiRef.current.state.pagination.paginationModel.page;

    const skipAmount = adminStatusUpdated ? currentPage * 10 : products.length;

    setProducts([]);
    productStatuses[status].products = [];
    try {
      const response = await getProductClient.sendRequest({
        url: `/owner/product/get?skip=${skipAmount}&descending=true`,
        method: "post",
        body: {
          status: productStatuses[status].name,
          getCount: getCount,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log(response);
      if (response && response.products) {
        productStatuses[status].products = response.products;

        if (getCount) {
          setCurrentCount(response.count);
          console.log(response.count);
        }

        // const allProducts = [...products, ...productStatuses[status].products];

        let allProducts;

        if (adminStatusUpdated) {
          allProducts = [...productStatuses[status].products];
        } else {
          allProducts = [...products, ...productStatuses[status].products];
        }

        setProducts(allProducts);
      }
    } catch (e) {
      console.log(getProductClient.error);
    }
  };

  const getProductById = async (productId: string) => {
    try {
      const response = await searchBarClient.sendRequest({
        url: "/owner/product/get/id",
        method: "post",
        body: { productId: productId },
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log("response - product by id");
      console.log(response);
      if (response) {
        setSearchBarResults([response.products]);
        console.log("searchbar results");
        console.log(searchBarResults);
      }
    } catch (e) {
      console.log(e);
      console.log(searchBarClient.error);
    }
  };

  const getProductsByVendor = async (vendorId: string) => {
    try {
      const response = await getProductClient.sendRequest({
        url: "/owner/product/getByVendor",
        method: "post",
        body: {
          vendorId: vendorId,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });

      if (response && response.products) {
        setProducts(response.products);
      }
    } catch (error) {
      console.log("Error fetching products by vendor:", error);
      console.log(getProductClient.error);
    }
  };

  useEffect(() => {
    // getProducts(true);
    if (props.vendorId) {
      getProductsByVendor(props.vendorId);
    } else {
      getProducts(true);
    }
    // console.log('hi')
  }, [status]);

  const handleEvent = (data: any) => {
    console.log(data);
    console.log(data.row);
    // setSelectedProduct(null);

    setTimeout(() => {
      setOpenReview(true);
      setSelectedProduct(data.row);
    }, 50);
  };

  return displaySearchBarResults ? (
    // show search bar results

    <SearchBarList
      toggleSearchBarList={() =>
        setDisplaySearchBarResults(!displaySearchBarResults)
      }
      columns={columns}
      rows={searchBarResults}
      isLoading={searchBarClient.isLoading}
    />
  ) : (
    // otherwise, show the main Products page

    <div className="page">
      <SnackbarErrorClient client={getProductClient} />

      <MultipleImagePopup
        imageSrcs={imageModalSrcs}
        open={openImageModal}
        setOpen={setOpenImageModal}
      />

      <ReviewProduct
        getProducts={getProducts}
        open={openReview}
        setOpen={setOpenReview}
        product={selectedProduct}
      />

      <div className="pageUpper">
        <p className="pageTitle">Products</p>
        <div className="pageUpperCTAs">
          {productStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setCurrentCount(0);
                setProducts([]);
                setStatus(i);
                dataGridApiRef.current.setPage(0);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      </div>

      {props.returnToOriginalComponent && (
        // if called from another component, display arrow to return to that component
        <FaArrowLeft onClick={props.returnToOriginalComponent} />
      )}

      <SearchBar
        fetchDataById={getProductById}
        toggleSearchBarList={() =>
          setDisplaySearchBarResults(!displaySearchBarResults)
        }
      />

      <TableList
        // onRowClick={handleEvent}
        rows={products}
        isLoading={getProductClient.isLoading}
        columns={columns}
        rowsCount={currentCount}
        getItems={() => getProducts(false)}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />
    </div>
  );
};

export default ProductList;