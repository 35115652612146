import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal } from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";
import { ICategory } from "../../Models/ICategory";
import { Cropper, getCroppedImg } from "react-cropper-custom";
import ImageUpload from "../ImageUpload/ImageUpload";
import imageCompression from "browser-image-compression";

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

interface AddUpdateCategoryProps {
  parentId?: any | null;
  parentName?: any | null;
  isFashion?: boolean | null;

  sellRateAmount?: number | null;
  sellRatePercentage?: number | null;
  parentGender?: any | null;
  parentForKids?: any | null;
  parentSize?: any | null;

  category?: ICategory | null;

  open: any;
  setOpen: any;
  getCategories: any;
}

const AddUpdateCategory: React.FunctionComponent<AddUpdateCategoryProps> = (
  props
) => {
  const [error, setError] = useState("");
  const handleClose = () => props.setOpen(false);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [initImageSrc, setInitImageSrc] = useState<any>(null);
  const [imageSrcMale, setImageSrcMale] = useState<any>(null);
  const [initImageSrcMale, setInitImageSrcMale] = useState<any>(null);

  const [imageSrcFemale, setImageSrcFemale] = useState<any>(null);
  const [initImageSrcFemale, setInitImageSrcFemale] = useState<any>(null);

  const [imageSrcKids, setImageSrcKids] = useState<any>(null);
  const [initImageSrcKids, setInitImageSrcKids] = useState<any>(null);

  // const [img, setImg] = useState("./2521.jpg");
  const [zoom, setZoom] = useState(1);
  const [img, setImg] = useState("./2521.jpg");

  const submitCategoryClient = useHttpClient();

  let defValues = {};

  useEffect(() => {
    setError("");

    // if (props.product) {
    //   console.log(props.product);
    defValues = {};
    setImage({ preview: "", data: "" });
    if (props.category?.logo) {
      setInitImageSrc(props.category?.logo);
    }else{
      setInitImageSrc(null);

    }
    if (props.category?.images) {
      if (props.category?.images?.male) {
        setInitImageSrcMale(props.category?.images?.male);
      } else {
        setInitImageSrcMale(null);
      }
      if (props.category?.images?.female) {
        setInitImageSrcFemale(props.category?.images?.female);
      } else {
        setInitImageSrcFemale(null);
      }
      if (props.category?.images?.kids) {
        setInitImageSrcKids(props.category?.images?.kids);
      } else {
        setInitImageSrcKids(null);
      }
    } else {
      setInitImageSrcMale(null);

      setInitImageSrcFemale(null);

      setInitImageSrcKids(null);
    }
    setImageSrc(null);
    setImageSrcMale(null);
    setImageSrcFemale(null);
    setImageSrcKids(null);

    reset(defValues);
    // } else {
    // }
    console.log("hoooooooo");
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const onSubmit = async () => {
    const values = control._formValues;

    try {
      let formData = new FormData();

      submitCategoryClient.setLoading(true);

      let imagesTypes=[];

      console.log(imageSrc);
      console.log(imageSrcMale);
      console.log(imageSrcFemale);
      console.log(imageSrcKids);

      if (imageSrc != null) {
        console.log("IMMMM SOURCE")
        const compressedFile = await imageCompression(imageSrc, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        console.log("IMMMM SOURCE")


        formData.append("images", compressedFile);
        imagesTypes.push("main")

      }

      if (imageSrcMale != null) {
        const compressedFileM = await imageCompression(imageSrcMale, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        formData.append("images", compressedFileM);
        imagesTypes.push("male")

      }

      if (imageSrcFemale != null) {
        const compressedFileF = await imageCompression(imageSrcFemale, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        formData.append("images", compressedFileF);
        imagesTypes.push("female")

      }

      if (imageSrcKids != null) {
        const compressedFilek = await imageCompression(imageSrcKids, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        formData.append("images", compressedFilek);
        imagesTypes.push("kids")

      }
      submitCategoryClient.setLoading(false);
      formData.append("imagesTypes", (JSON.stringify(imagesTypes)));

      formData.append("name", values.name);
      formData.append("nameAr", values.nameAr);
      if (values.gender?.value === "male" || values.gender?.value === "female")
        formData.append("gender", values.gender?.value);
      formData.append("sellRateAmount", values.sellRateAmount);
      formData.append("sellRatePercentage", values.sellRatePercentage);
      if (values.sizeType?.value && values.sizeType?.value !== "none")
        formData.append("size", values.sizeType?.value);
      formData.append("forKids", values.forKids?.value);

      if (props.parentId) {
        formData.append("parent", props.parentId);
      }

      console.log(image);
      if (props.category) {
        formData.append("id", props.category.id ?? "");

        console.log("FORMDATAAAA", formData.get('images'));

        const response = await submitCategoryClient.sendRequest({
          url: "/owner/category",
          method: "put",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
      } else {
        const response = await submitCategoryClient.sendRequest({
          url: "/owner/category",
          method: "post",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
      }

      props.setOpen(false);
      props.getCategories();
    } catch (e) {
      console.log(e);
      submitCategoryClient.setLoading(false);
    }
    console.log(values);
  };

  const handleFileChange = (e: any) => {
    if (e.target.files[0]) {
      const img = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      };
      setImage(img);
      setImg(img.data);
      console.log(img.data);
    }
  };

  const onCropComplete = async (croppedArea: Area) => {
    try {
      const imageS = await getCroppedImg(image.preview, croppedArea);
      console.log(imageS);

      setImg(imageS);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <SnackbarErrorClient client={submitCategoryClient} />
      <PageLoader loading={submitCategoryClient.isLoading} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.category ? "Edit" : "Create"} {props.parentName ?? ""}{" "}
            Category
          </p>

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <FormInputText
              rules={{
                required: true,
              }}
              value={props.category?.name}
              control={control}
              name="name"
              label={"name"}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              value={props.category?.nameAr}
              name="nameAr"
              label={"Name Arabic"}
            />
            {!props.parentId && (
              <FormInputText
                rules={{
                  required: true,
                }}
                control={control}
                type="number"
                value={props.category?.sellRate?.sellRateAmount}
                name="sellRateAmount"
                label={"Sell Rate Amount"}
              />
            )}

            {!props.parentId && (
              <FormInputText
                rules={{
                  required: true,
                }}
                control={control}
                value={props.category?.sellRate?.sellRatePercentage}
                type="number"
                name="sellRatePercentage"
                label={"Sell Rate Percentage"}
              />
            )}
            {/* <input type="file" name="file" title="Main I" onChange={handleFileChange}></input> */}
            <ImageUpload
              title={"Main Image"}
              img={initImageSrc}
              setImg={setImageSrc}
            />

            {props.isFashion == true && (
              <>
                <ImageUpload
                  img={initImageSrcMale}
                  title={"Male Image"}
                  setImg={setImageSrcMale}
                />
                <ImageUpload
                  img={initImageSrcFemale}
                  title={"Female Image"}
                  setImg={setImageSrcFemale}
                />
                <ImageUpload
                  img={initImageSrcKids}
                  title={"Kids Image"}
                  setImg={setImageSrcKids}
                />
              </>
            )}

            {!props.parentGender && (
              <FormInputText
                rules={{}}
                inputType="autocomplete"
                options={[
                  { label: "All", value: "" },
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
                value={props.category?.gender}
                control={control}
                name="gender"
                label={"gender"}
              />
            )}

            {!props.parentForKids && (
              <FormInputText
                rules={{}}
                inputType="autocomplete"
                options={[
                  { label: "True", value: "true" },
                  { label: "False", value: "false" },
                ]}
                value={props.category?.forKids}
                control={control}
                name="forKids"
                label={"forKids"}
              />
            )}

            {!props.parentSize && (
              <FormInputText
                rules={{}}
                inputType="autocomplete"
                options={[
                  { label: "None", value: "none" },
                  { label: "Clothing", value: "clothing" },
                  { label: "Footwear", value: "footwear" },
                  { label: "Arts", value: "arts" },
                  { label: "Furniture", value: "furniture" },
                ]}
                value={props.category?.size}
                control={control}
                name="sizeType"
                label={"size Type"}
              />
            )}

            {error && <Alert severity="error">{error}</Alert>}

            <CustomButton
              text={props.category ? "Edit" : "Create"}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdateCategory;
