import React, { useEffect, useMemo, useState } from "react";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useHttpClient } from "../../Hooks/HttpHook";
import { ICustomer } from "../../Models/ICustomer";
import { ILocation } from "../../Models/ILocation";
import { IProvince } from "../../Models/IProvince";
import { ICity } from "../../Models/ICity";
import CustomerList from "../../Pages/CustomerList/CustomerList";
import PageLoader from "../PageLoader";

interface CustomerLocationPopupProps {
  customerLocation?: ILocation | null;
  province?: IProvince | null;
  city?: ICity | null;
  open: any;
  setOpen: any;
}

const CustomerLocationPopup: React.FunctionComponent<
  CustomerLocationPopupProps
> = (props) => {
  const handleClose = () => props.setOpen(false);
  const customerLocationClient = useHttpClient();



  useEffect(() => {

  },[]);


  const customerLocationData = {

    "Apartment": props.customerLocation?.apartment,
    "Building": props.customerLocation?.building,
    "Street": props.customerLocation?.street,
    "Floor": props.customerLocation?.floor,
    "Latitude": props.customerLocation?.position?.latitude,
    "Longtitude": props.customerLocation?.position?.longitude,
    
    "City Name": props.city?.name,
    "City Name AR": props.city?.nameAr,

    "Province Name": props.province?.name,
    "Province Name AR": props.province?.nameAr,
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
  

        <Box className="reviewProductBox">
        <PageLoader loading={customerLocationClient.isLoading}/>

          <Table>
            <TableBody>
              { Object.entries(customerLocationData).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              )) }
            </TableBody>
          </Table>          

        </Box>
      </Modal>
    </div>
  );
};

export default CustomerLocationPopup;
