// SubtypeSelector.tsx
import React from "react";
import { Autocomplete, TextField } from "@mui/material";

interface SubtypeSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

const SubtypeSelector: React.FC<SubtypeSelectorProps> = ({ value, onChange }) => {
  const options = [
    { label: "Product Categories", value: "productCategories" },
    { label: "Product Discounts", value: "productDiscounts" },
    { label: "Vendor Categories", value: "vendorCategories" },
    { label: "Discount Product Ads", value: "discountProductsAds" },
    { label: "Category Products Ads", value: "categoryProductsAds" },
    { label: "Vendor Products Ads", value: "vendorProductsAds" },
  ];

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label="Sub Type" variant="outlined" />
      )}
      value={options.find((option) => option.value === value)}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange(newValue.value);
        }
      }}
    />
  );
};

export default SubtypeSelector;
