import { Alert, Snackbar } from "@mui/material";

interface SnackbarErrorProps {
    message: string,
    onClose?: () => void;

}

const SnackbarError: React.FunctionComponent<SnackbarErrorProps> = (props) => {
  return (
    <Snackbar
      onClose={ props.onClose ?? (() => null)}
      open={props.message ? true : false}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity="error" sx={{ width: "100%" }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarError;
