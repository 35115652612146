import React, { useEffect, useMemo, useState } from "react";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useHttpClient } from "../../Hooks/HttpHook";
import { ICustomer } from "../../Models/ICustomer";
import { ILocation } from "../../Models/ILocation";
import { IProvince } from "../../Models/IProvince";
import { ICity } from "../../Models/ICity";
import CustomerList from "../../Pages/CustomerList/CustomerList";
import PageLoader from "../PageLoader";
import { IProduct } from "../../Models/IProduct";
import { IVendor } from "../../Models/IVendor";
import { ICategory } from "../../Models/ICategory";

interface productPopupProps {
  productId?: String | null;

  open: any;
  setOpen: any;
}

const ProductPopup: React.FunctionComponent<
productPopupProps
> = (props) => {
  const handleClose = () => props.setOpen(false);
  const productClient = useHttpClient();
  const [product, setProduct] = useState<IProduct>();

  useEffect(() => {
    getProduct();
  });

  console.log(product);
  

  let data = {
    "id": product?.id ? product.id:  "",
    "Name": product?.name ? product.name:  "",
    "Price": product?.price ? product.price:  "",
    "Description": product?.description ? product.description:  "",
    "Category": (product? product.category : "" ) ? (product?.category as ICategory).name:  "", 
    "Status": product?.status ? product.status:  "",
    "Units Sold": product?.unitsSold ? product.unitsSold:  "",
    "Quantity": product?.quantity ? product.quantity:  "",
    "Vendor Name": (product? product.vendor : "" ) ? (product?.vendor as IVendor).name:  "" 
  }


  const getProduct = async() => {
    try {
        if (props.productId) {
          console.log(`Searching for customer location with id: ${props.productId}`);
          
          const response = await productClient.sendRequest({
            url: `/owner/product/product?productId=${props.productId}`,
            method: "get",
            headers: {
              headers: { "Content-Type": "application/json" },
              // withCredentials: true
            },
            body:null,
          });
          console.log("customer locations: ");
          console.log(response);
          if (response) {
            setProduct(response)
          }
        }
      
      } catch (error) {
          console.log(error)
      }
  };



 



  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <PageLoader loading={!data} />
          {data && (
            <Table>
              <TableBody>
                {Object.entries(data).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>
                      {typeof value === "object" ? JSON.stringify(value) : value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ProductPopup;
