import React from "react";
import {
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

interface IconUploadSelectorProps {
  uploadNewImage: boolean;
  handleIconUploadChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const IconUploadSelector: React.FC<IconUploadSelectorProps> = ({
  uploadNewImage,
  handleIconUploadChange,
}) => {
  return (
    <>
      <Typography variant="h6">Section Icon</Typography>
      <RadioGroup
        row
        aria-label="icon-upload"
        name="icon-upload"
        value={uploadNewImage}
        onChange={handleIconUploadChange}
      >
        <FormControlLabel
          value={true}
          control={<Radio color="primary" />}
          label="Upload New Image"
          labelPlacement="end"
        />
        <FormControlLabel
          value={false}
          control={<Radio color="primary" />}
          label="Use Existing Image"
          labelPlacement="end"
        />
      </RadioGroup>
    </>
  );
};

export default IconUploadSelector;
